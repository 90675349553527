import { MoneyMovementStatus } from "@/types";

type Props = {
  status: MoneyMovementStatus;
};

export const MoneyMovementStatusBadge: React.FC<Props> = ({ status }) => {
  if (status === MoneyMovementStatus.CANCELED) {
    return <span className="badge badge-error w-28">Отменено</span>;
  }

  if (status === MoneyMovementStatus.COMPLETED) {
    return <span className="badge badge-success w-28">Выполнено</span>;
  }

  if (status === MoneyMovementStatus.IN_PROCESS) {
    return <span className="badge badge-info  w-28">В процессе</span>;
  }

  return <div></div>;
};
