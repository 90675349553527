import { LegalInformationStatus } from '@/types';

const { MODERATION, REJECTED, CONFIRMED } = LegalInformationStatus;

type Props = {
  status: LegalInformationStatus | undefined;
  size?: 'md';
};

export const LegalInformationStatusBadge: React.FC<Props> = ({
  status,
  size,
}) => {
  let className =
    status === undefined || status === REJECTED
      ? 'badge-warning'
      : status === MODERATION
      ? 'badge-info'
      : status === CONFIRMED
      ? 'badge-success'
      : '';

  className += ' badge  lowercase xl:uppercase font-normal';
  className += size === 'md' ? ' badge-md' : 'badge-md xl:badge-lg';

  const text =
    status === undefined || status === REJECTED
      ? 'демо'
      : status === MODERATION
      ? 'на проверке'
      : status === CONFIRMED
      ? 'активен'
      : '';

  return <span className={className}>{text}</span>;
};
