import React, { useEffect, useState } from "react";
import { NotificationManager as notify } from "react-notifications";

import { EyeIcon } from "@/components/Icons/EyeIcon";
import { EyeOffIcon } from "@/components/Icons/EyeOffIcon";
import { useChangePasswordMutation } from "@/redux/api/curatorAPI";
import { IErrorResponse } from "@/types";

export const ResetPasswordBlock = () => {
  const [password, setPassword] = useState<string>();
  const [newPassword, setNewPassword] = useState<string>();
  const [isPasswordType, setPasswordType] = useState(true);
  const [isNewPasswordType, setNewPasswordType] = useState(true);
  const [isPasswordChanged, PasswordChanged] = useState(false);
  const [isNewPasswordChanged, setNewPasswordChanged] = useState(false);

  const [changePassword, { error, isSuccess }] = useChangePasswordMutation();

  const passwordBtnSubmit = isPasswordChanged || isNewPasswordChanged;

  const passwordChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    PasswordChanged(true);
  };

  const newPasswordChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value);
    setNewPasswordChanged(true);
  };

  const changePasswordHandler = () => {
    if (!password) return notify.error("Введите текущий пароль");
    if (!newPassword) return notify.error("Введите новый пароль");
    changePassword({
      password,
      newPassword,
    });
  };

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    notify.error(data.message);
  }, [error]);

  useEffect(() => {
    if (!isSuccess) return;
    notify.success("Пароль успешно изменен!");
    setPassword("");
    setNewPassword("");
    PasswordChanged(false);
    setNewPasswordChanged(false);
  }, [isSuccess]);

  return (
    <div className=" w-full lg:max-w-sm">
      <div className="text-xl font-medium mb-3">Смена пароля</div>
      <label className="label">
        <span className="label-text">Текущий пароль</span>
      </label>
      <div className="form-control lg:w-2/3 flex-row gap-5 items-center relative">
        <input
          value={password}
          onChange={passwordChangeHandler}
          type={isPasswordType ? "password" : "text"}
          className="input pr-8 input-bordered w-full input-sm"
        />
        <span
          className="cursor-pointer eye absolute  right-2"
          onClick={() => setPasswordType(!isPasswordType)}
        >
          {isPasswordType ? <EyeIcon /> : <EyeOffIcon />}
        </span>
      </div>
      <label className="label mt-3">
        <span className="label-text">Новый пароль</span>
      </label>
      <div className="form-control lg:w-2/3 flex-row gap-5 items-center relative">
        <input
          value={newPassword}
          onChange={newPasswordChangeHandler}
          type={isNewPasswordType ? "password" : "text"}
          className="input input-bordered w-full input-sm"
        />
        <span
          className="cursor-pointer eye absolute right-2"
          onClick={() => setNewPasswordType(!isNewPasswordType)}
        >
          {isNewPasswordType ? <EyeIcon /> : <EyeOffIcon />}
        </span>
      </div>
      <button
        onClick={changePasswordHandler}
        className={`btn btn-primary btn-sm mt-5 ${
          passwordBtnSubmit ? "" : "btn-disabled"
        }`}
      >
        Сменить
      </button>
    </div>
  );
};
