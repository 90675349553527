import { routing } from '@/constants';
import { Link } from 'react-router-dom';

type Props = {
  balance: number | undefined;
};

export const Balance: React.FC<Props> = ({ balance }) => {
  return (
    <div className='balance gap-3 lg:gap-5 mr-3 flex '>
      <Link to={`/${routing.CABINET}/${routing.FINANCES}`}>
        Баланс:{' '}
        <span className='lg:text-lg font-medium'>
          {balance ? balance : 0} K
        </span>
      </Link>
    </div>
  );
};
