import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { tokenLSKey } from "@/constants";
import { INews, INewsResponse } from "@/types";

export const newsAPI = createApi({
  reducerPath: "newsAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/news",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["News"],
  endpoints: (builder) => ({
    getAllNews: builder.query<INewsResponse, { offset: number; limit: number }>(
      {
        query: ({ offset, limit }) => ({
          url: "",
          method: "GET",
          params: { offset, limit },
        }),
        providesTags: (result) => ["News"],
      }
    ),
    getNewsById: builder.query<INews, { id: number }>({
      query: ({ id }) => ({
        url: `${id}`,
        method: "GET",
      }),
      providesTags: (result) => ["News"],
    }),
  }),
});

export const { useGetAllNewsQuery, useGetNewsByIdQuery } = newsAPI;
