import { ITelephony } from "@/types";

type Props = {
  number: string;
  telephony: ITelephony[];
  tag?: keyof JSX.IntrinsicElements;
};

export const TelephonyCompanyCeil: React.FC<Props> = ({
  number,
  telephony,
  tag,
}) => {
  const Tag = tag || "td";

  if (number === "p_manually") return <Tag>Вручную (Частное лицо)</Tag>;
  if (number === "c_manually") return <Tag>Вручную (Компания)</Tag>;
  if (number === "c_api") return <Tag>API (Компания)</Tag>;
  if (number === "p_api") return <Tag>API (Частное лицо)</Tag>;

  if (!telephony.length) return <Tag>{number}</Tag>;

  const data: { [key: string]: string } = {};
  telephony.forEach((t) => (data[t.number] = t.name));

  return <Tag title={number}> {data[number] ? data[number] : number}</Tag>;
};
