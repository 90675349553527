import { MoneyMovementStatus } from "@/types";
import { useState } from "react";

type Props = {
  valueHandler: (status: string) => void;
};

const statuses = (
  Object.keys(MoneyMovementStatus) as Array<keyof typeof MoneyMovementStatus>
).map((key) => MoneyMovementStatus[key]);

export const MoneyMovementStatusSelect: React.FC<Props> = ({
  valueHandler,
}) => {
  const [selected, setSelected] = useState<string>("all");

  const changeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const status = event.target.value;
    setSelected(status);
    valueHandler(status);
  };

  return (
    <>
      <label className="label">
        <span className="label-text">Статус</span>
      </label>
      <select
        className="select select-sm select-bordered w-full lg:max-w-xs "
        value={selected}
        onChange={changeSelect}
      >
        <option value="all">Все Статусы </option>
        {statuses.map((status) => (
          <option value={status} key={status}>
            {status === MoneyMovementStatus.CANCELED
              ? "Отменено"
              : status === MoneyMovementStatus.COMPLETED
              ? "Выполнено"
              : status === MoneyMovementStatus.IN_PROCESS
              ? "В процессе"
              : ""}
          </option>
        ))}
      </select>
    </>
  );
};
