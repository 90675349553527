import { TelephonyType } from "@/types";

type Props = {
  name: string;
  number: string;
  type: TelephonyType;
};

const TelephonyBadge = ({ type }: { type: TelephonyType }) => {
  return (
    <>
      {type === TelephonyType.COMPANY && (
        <span className="badge badge-accent badge-md">КП</span>
      )}
      {type === TelephonyType.PRIVATE && (
        <span className="badge badge-info badge-md">ЧМ</span>
      )}
    </>
  );
};

export const TelephonyCeil: React.FC<Props> = ({ name, number, type }) => {
  return (
    <div className="pb-1">
      {name ? (
        <div className="tooltip" data-tip={name}>
          {number} <TelephonyBadge type={type} />
        </div>
      ) : (
        <>
          {number} <TelephonyBadge type={type} />
        </>
      )}

      <br />
    </div>
  );
};
