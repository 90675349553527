import { RootState } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  leadsSearchValue: string;
  webmastersSearchValue: string;
  webmasterLeadsSearchValue: string;
} = {
  leadsSearchValue: "",
  webmastersSearchValue: "",
  webmasterLeadsSearchValue: "",
};

export const searchesSlice = createSlice({
  initialState,
  name: "searches",
  reducers: {
    setLeadsSearchValue: (state, action: PayloadAction<string>) => {
      state.leadsSearchValue = action.payload;
    },

    setWebmastersSearchValue: (state, action: PayloadAction<string>) => {
      state.webmastersSearchValue = action.payload;
    },

    setWebmasterLeadsSearchValue: (state, action: PayloadAction<string>) => {
      state.webmasterLeadsSearchValue = action.payload;
    },
  },
});

export default searchesSlice.reducer;

export const {
  setLeadsSearchValue,
  setWebmastersSearchValue,
  setWebmasterLeadsSearchValue,
} = searchesSlice.actions;
export const searches = (state: RootState) => state.searches;
