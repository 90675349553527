import { MoneyMovementType } from "@/types";
import { useState } from "react";

type Props = {
  valueHandler: (type: string) => void;
};

const types = (
  Object.keys(MoneyMovementType) as Array<keyof typeof MoneyMovementType>
).map((key) => MoneyMovementType[key]);

export const MoneyMovementTypeSelect: React.FC<Props> = ({ valueHandler }) => {
  const [selected, setSelected] = useState<string>("all");

  const changeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const type = event.target.value;
    setSelected(type);
    valueHandler(type);
  };

  return (
    <>
      <label className="label">
        <span className="label-text">Тип</span>
      </label>
      <select
        className="select select-sm select-bordered w-full lg:max-w-xs "
        value={selected}
        onChange={changeSelect}
      >
        <option value="all">Все типы </option>
        {types.map((type) => (
          <option value={type} key={type}>
            {type === MoneyMovementType.PAYMENT
              ? "Вывод средств"
              : type === MoneyMovementType.CURATOR_INCOME
              ? "Выплата за лид вебмастера"
              : ""}
          </option>
        ))}
      </select>
    </>
  );
};
