export const Logo = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 953.000000 1890.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,1890.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M1955 18876 c-65 -30 -142 -116 -194 -215 -56 -108 -155 -455 -190
-664 -7 -37 -18 -139 -26 -225 -8 -86 -20 -193 -27 -237 -7 -54 -12 -290 -12
-730 -1 -580 1 -656 16 -705 9 -30 19 -89 23 -130 11 -127 44 -277 106 -473
32 -103 59 -194 59 -202 0 -13 -25 -15 -149 -15 -320 0 -491 -64 -694 -259
-125 -121 -183 -218 -247 -416 -77 -243 -117 -711 -74 -867 l17 -58 -29 -58
c-17 -31 -68 -131 -115 -222 -145 -280 -254 -557 -325 -825 -77 -293 -100
-495 -90 -805 10 -329 43 -556 112 -770 39 -119 140 -334 154 -325 16 10 21
63 10 102 -17 60 -52 543 -51 706 3 610 233 1334 594 1875 38 57 71 102 73 99
2 -2 -86 -382 -197 -843 -110 -461 -207 -876 -214 -921 -28 -168 -46 -514 -48
-923 -2 -391 -1 -408 20 -491 13 -48 23 -105 23 -127 0 -56 58 -275 120 -457
54 -157 143 -355 160 -355 15 0 12 89 -4 146 -9 30 -16 120 -19 239 -4 123
-11 211 -21 250 -12 48 -15 122 -15 365 0 168 4 346 9 395 5 50 14 160 20 245
13 176 86 658 136 890 69 325 139 578 248 892 132 382 213 595 242 645 l29 48
3 -65 c2 -36 -1 -123 -7 -194 -21 -254 -12 -717 24 -1151 23 -275 20 -263 61
-271 19 -3 34 -8 34 -11 0 -3 -21 -70 -46 -149 -42 -133 -49 -147 -94 -193
-84 -85 -204 -357 -265 -600 -33 -135 -45 -204 -61 -376 -8 -82 -21 -179 -29
-215 -18 -79 -21 -520 -4 -735 31 -425 118 -851 233 -1153 62 -162 137 -301
268 -498 113 -170 136 -199 205 -255 43 -35 134 -118 203 -185 246 -237 532
-420 678 -432 l64 -5 -5 389 c-2 214 -12 522 -22 684 -18 316 -16 784 4 870 6
25 15 129 21 233 16 313 49 457 104 457 41 0 50 -92 43 -470 -3 -179 3 -1036
15 -1905 15 -1152 18 -1603 10 -1666 -14 -116 -48 -212 -151 -429 -129 -269
-151 -350 -151 -544 0 -122 21 -239 86 -476 31 -112 38 -156 41 -260 5 -121 4
-128 -31 -237 -46 -148 -53 -219 -31 -318 24 -108 87 -225 216 -400 61 -82
175 -254 255 -381 175 -281 216 -338 314 -434 168 -164 497 -366 733 -449 186
-65 263 -76 557 -76 444 1 591 39 867 224 165 111 275 211 463 421 176 196
257 270 353 320 206 108 303 209 365 379 33 91 49 181 61 351 34 443 42 504
92 682 49 173 59 244 66 428 9 261 -18 453 -78 570 -36 69 -66 103 -154 172
-125 98 -165 159 -185 281 -31 199 -35 427 -32 1814 l2 1391 -22 94 c-21 88
-22 115 -23 485 0 429 2 446 50 390 47 -53 57 -111 95 -552 35 -400 41 -573
47 -1292 5 -569 8 -703 19 -703 40 0 128 26 187 56 88 43 283 184 412 295 540
467 811 1036 890 1872 50 532 34 1282 -36 1632 -11 55 -45 174 -76 265 -35
104 -71 240 -98 370 -38 187 -41 207 -28 238 29 70 45 226 50 512 6 310 -6
545 -37 769 -11 75 -23 188 -26 251 l-6 115 30 -30 c71 -70 346 -603 457 -885
120 -304 128 -332 341 -1313 70 -323 131 -584 136 -579 16 16 37 147 47 292
13 179 13 587 0 695 -5 44 -13 142 -19 219 -12 168 -20 215 -71 421 -92 367
-182 641 -311 943 -120 283 -139 322 -253 532 -103 189 -179 280 -250 299 -71
19 -125 14 -188 -20 -32 -17 -60 -29 -62 -27 -1 2 -39 145 -83 318 -102 395
-121 454 -151 458 -16 3 -34 -8 -61 -38 -35 -38 -80 -60 -122 -60 -14 0 -18 8
-18 33 1 47 31 107 110 217 252 351 304 638 270 1507 -15 382 -34 534 -165
1298 -48 282 -140 629 -218 824 l-61 153 -64 -5 c-98 -8 -179 -53 -282 -156
-131 -131 -230 -294 -309 -506 -186 -504 -191 -519 -191 -669 0 -43 -7 -142
-16 -220 -16 -132 -16 -151 1 -303 22 -198 17 -267 -26 -365 -24 -56 -32 -86
-31 -130 1 -62 -1 -57 139 -378 134 -305 173 -437 159 -532 -4 -24 -33 -96
-65 -159 l-58 -114 -103 -3 -103 -3 7 -67 c4 -37 15 -116 26 -177 45 -253 54
-448 25 -579 -30 -141 -109 -264 -180 -282 -45 -11 -105 3 -144 33 -20 16 -70
74 -111 128 -41 54 -80 100 -86 102 -13 4 -24 -63 -24 -151 0 -34 -7 -109 -14
-167 -45 -336 -161 -498 -380 -528 -105 -15 -121 -9 -162 62 -88 151 -148 342
-228 732 -30 145 -59 273 -66 284 -6 12 -18 21 -28 21 -32 1 -100 38 -145 79
-57 52 -84 115 -102 237 -13 90 -34 144 -54 144 -11 0 -29 -45 -156 -384 -187
-499 -318 -746 -433 -813 -43 -26 -56 -28 -147 -28 l-100 0 -13 60 c-13 60
-11 333 4 538 l7 87 -55 -24 c-68 -30 -151 -46 -239 -46 l-68 0 -10 38 c-41
158 -39 375 8 667 17 105 31 222 31 262 l0 72 -115 -56 c-106 -52 -120 -56
-184 -56 -81 0 -109 14 -145 71 -56 89 -86 209 -150 597 -43 259 -53 337 -116
900 -41 373 -61 509 -115 795 -69 368 -97 477 -177 690 -129 346 -287 560
-414 560 -16 0 -52 -11 -79 -24z m-39 -3892 c35 -45 102 -114 149 -154 92 -78
122 -113 135 -160 7 -26 6 -30 -10 -30 -31 0 -153 41 -213 72 -31 17 -64 28
-72 25 -31 -12 -68 -116 -124 -350 -33 -133 -71 -273 -85 -310 -30 -80 -78
-153 -107 -162 -25 -8 -20 -12 -54 54 -15 30 -45 72 -66 93 -22 21 -39 44 -39
50 0 26 45 78 117 136 93 75 120 111 135 183 9 45 8 63 -6 109 -17 58 -31 79
-84 123 -30 24 -41 27 -109 27 -92 0 -141 -14 -188 -52 -53 -44 -245 -272
-366 -433 l-104 -140 -3 47 c-2 33 6 75 29 140 96 281 235 475 396 551 67 31
71 32 248 37 114 3 188 10 201 17 53 30 92 104 109 206 6 39 6 39 27 20 11
-10 49 -55 84 -99z m2117 -1550 c109 -55 182 -109 612 -445 306 -239 487 -397
601 -524 75 -84 130 -162 121 -172 -4 -3 -33 4 -64 16 -173 66 -201 72 -272
51 -169 -48 -238 -410 -182 -945 50 -470 103 -904 131 -1065 34 -194 42 -359
21 -423 -7 -22 -17 -35 -22 -30 -4 4 -36 89 -70 188 -225 659 -503 1118 -969
1600 -135 139 -162 176 -170 230 l-7 44 46 19 c25 11 64 23 86 26 22 4 61 14
87 23 43 15 47 20 53 55 6 34 15 43 78 84 120 77 167 133 200 239 64 208 -19
494 -246 838 -143 217 -148 248 -34 191z m-1434 -553 c88 -27 235 -113 488
-285 301 -204 392 -263 429 -276 17 -6 67 -44 109 -86 77 -73 79 -76 83 -131
l5 -56 -128 7 c-240 12 -452 53 -644 122 -343 124 -651 362 -651 505 0 59 29
126 68 159 78 64 135 74 241 41z m4659 -11 c62 -28 159 -128 167 -172 19 -99
-121 -242 -317 -324 -154 -65 -619 -184 -722 -184 -33 0 -90 -7 -126 -15 -107
-25 -309 -30 -420 -11 -52 9 -112 21 -132 27 -38 10 -38 10 -38 60 l0 50 63
29 c85 38 210 71 484 125 127 25 260 54 295 65 84 26 166 75 323 194 191 145
249 175 335 175 25 0 64 -8 88 -19z m-874 -820 c140 -36 283 -127 372 -238
245 -305 203 -768 -91 -1012 -62 -51 -188 -114 -275 -137 -89 -24 -281 -23
-370 1 -224 61 -424 249 -486 456 -40 134 -35 362 10 492 58 165 204 314 394
400 132 60 303 75 446 38z m-3194 -250 c294 -85 472 -415 382 -705 -76 -247
-270 -384 -542 -385 -160 0 -277 47 -386 155 -253 250 -204 687 97 864 43 25
202 82 254 91 53 9 116 3 195 -20z m1481 -2637 c24 -64 82 -266 154 -538 42
-159 103 -380 135 -490 33 -110 174 -614 314 -1120 401 -1450 569 -2043 633
-2235 l28 -85 47 -3 c25 -2 94 6 153 17 124 24 190 26 262 7 179 -48 255 -151
256 -346 0 -159 -44 -284 -138 -388 -67 -74 -88 -116 -120 -243 -32 -127 -54
-178 -105 -244 -35 -45 -60 -93 -60 -114 0 -5 46 8 103 27 172 59 303 48 358
-30 20 -28 20 -30 4 -48 -22 -24 -101 -54 -198 -76 -181 -41 -204 -61 -387
-330 -292 -430 -443 -590 -655 -697 -124 -62 -380 -134 -635 -178 -105 -18
-145 -20 -255 -14 -171 9 -225 19 -249 47 -18 22 -18 23 5 29 13 4 123 8 244
9 162 2 207 5 170 11 -27 5 -69 6 -91 3 -42 -6 -311 29 -351 46 -13 5 -23 16
-23 25 0 10 -10 15 -28 15 -108 0 -399 177 -550 334 -82 86 -130 166 -195 326
-18 47 -66 140 -104 207 -88 153 -108 220 -100 340 3 48 10 102 17 119 10 29
15 32 74 38 114 11 166 32 222 90 68 70 79 112 78 291 -1 104 -11 201 -37 375
-59 394 -58 413 14 607 22 59 67 215 99 345 84 335 287 1172 315 1303 13 61
43 180 65 265 23 85 88 353 145 595 243 1032 303 1263 340 1310 15 20 15 20
30 1 26 -34 55 -125 100 -311 24 -99 128 -484 230 -855 103 -371 200 -729 215
-795 16 -66 69 -271 118 -455 49 -184 142 -546 208 -805 98 -389 123 -478 149
-517 16 -26 35 -47 42 -47 7 -1 16 -9 22 -18 7 -13 13 -15 21 -7 14 14 80
-229 -520 1914 -280 998 -532 1899 -560 2003 -29 103 -56 187 -61 187 -20 0
-39 -56 -83 -245 -26 -110 -111 -452 -188 -760 -194 -772 -367 -1472 -487
-1970 -56 -231 -129 -519 -161 -640 -76 -281 -145 -552 -161 -632 -10 -54 -15
-63 -33 -63 -11 0 -77 20 -147 44 -151 52 -196 59 -274 43 -51 -10 -63 -17
-91 -54 -80 -105 -129 -292 -122 -462 7 -148 24 -186 148 -334 144 -171 211
-353 214 -577 l1 -125 -28 2 c-15 1 -76 32 -136 69 -134 81 -179 91 -230 49
-18 -15 -29 -31 -24 -36 4 -4 46 -11 92 -15 143 -12 245 -75 350 -217 24 -32
101 -156 172 -275 154 -257 200 -322 293 -420 83 -87 245 -198 338 -232 158
-58 218 -82 218 -89 0 -10 -46 -31 -68 -31 -23 0 -162 39 -187 53 -145 79
-191 109 -255 162 -148 124 -230 228 -375 480 -185 320 -259 416 -362 467 -56
27 -79 33 -166 37 l-100 6 -34 49 c-43 63 -40 84 17 113 108 55 220 51 320
-12 30 -19 60 -35 67 -35 18 0 17 158 -1 227 -31 115 -76 192 -205 347 -50 60
-87 115 -100 150 -71 190 -47 376 80 634 l71 144 157 -4 c146 -4 160 -6 213
-32 32 -15 73 -30 92 -33 34 -5 34 -5 45 43 5 27 218 886 471 1909 254 1023
478 1937 500 2030 48 214 72 277 121 326 47 47 75 50 90 12z"
        />
        <path
          d="M5925 11300 c-54 -14 -82 -28 -119 -61 -56 -48 -79 -102 -79 -179 0
-190 156 -308 338 -256 76 23 155 98 174 169 17 61 10 151 -18 204 -50 99
-180 153 -296 123z"
        />
        <path
          d="M3108 11374 c-107 -37 -172 -126 -171 -234 2 -204 191 -335 358 -248
162 85 203 273 89 406 -68 80 -177 110 -276 76z"
        />
        <path
          d="M5819 4778 c-1 -2 -5 -75 -8 -163 -5 -128 -10 -167 -24 -195 -20 -37
-79 -201 -104 -285 -20 -72 -29 -289 -14 -367 27 -134 113 -241 270 -334 108
-63 116 -77 115 -179 -1 -63 -8 -99 -34 -170 -60 -166 -165 -352 -261 -459
-118 -133 -324 -286 -421 -313 -40 -11 -59 -22 -67 -39 -8 -18 -30 -29 -93
-48 -46 -14 -121 -37 -168 -51 l-85 -25 75 5 c230 14 530 170 725 376 101 106
128 142 275 359 148 218 225 317 273 353 35 27 137 63 207 73 72 11 93 40 45
62 -39 18 -101 0 -255 -74 -75 -35 -139 -64 -144 -64 -14 0 2 118 23 166 11
24 40 72 64 106 60 85 77 121 127 273 55 168 91 240 130 260 60 31 110 174
110 315 0 279 -174 352 -588 246 l-94 -24 -19 42 c-10 24 -23 66 -30 94 -9 43
-24 73 -30 60z"
        />
        <path
          d="M3979 4698 c-19 -47 -14 -99 25 -244 25 -96 39 -177 51 -313 22 -231
16 -327 -31 -509 -19 -75 -38 -167 -41 -204 -5 -60 -4 -68 11 -68 64 0 112 73
150 230 45 187 49 230 43 454 -7 278 -38 461 -102 587 -17 35 -73 99 -86 99
-4 0 -13 -14 -20 -32z"
        />
        <path
          d="M5224 4685 c-98 -98 -160 -465 -135 -795 12 -168 28 -253 66 -360 41
-115 85 -190 129 -222 46 -33 86 -37 86 -9 0 38 -29 157 -68 281 -94 296 -117
420 -108 585 6 123 33 232 90 362 39 88 42 104 25 152 -14 39 -49 42 -85 6z"
        />
        <path
          d="M5055 17749 c-22 -25 -74 -117 -130 -233 -99 -205 -144 -276 -174
-276 -40 0 -184 85 -342 201 -90 67 -194 137 -232 155 -71 36 -162 64 -209 64
-24 0 -28 -4 -28 -26 0 -15 16 -62 36 -105 25 -56 34 -90 32 -117 l-3 -37
-155 -32 c-412 -85 -504 -112 -594 -177 -55 -39 -96 -91 -96 -122 0 -29 59
-52 229 -89 160 -35 216 -52 231 -70 7 -8 10 -15 7 -15 -2 0 -120 -25 -261
-55 -141 -31 -293 -61 -336 -67 l-80 -11 0 -82 c0 -317 147 -1159 206 -1182
23 -9 93 15 142 49 50 35 110 112 191 248 55 93 129 180 152 180 20 0 4 -412
-26 -655 -14 -115 -30 -253 -36 -305 -13 -106 -18 -425 -7 -435 14 -14 68 8
107 43 22 20 64 76 93 124 63 103 209 290 250 319 l28 20 1 -23 c4 -64 -6
-148 -51 -423 -61 -373 -74 -480 -66 -575 8 -96 18 -106 60 -58 123 141 494
1067 535 1336 11 69 35 147 52 166 4 5 32 -15 62 -43 93 -88 133 -193 191
-496 19 -99 41 -196 50 -216 15 -35 17 -36 82 -43 79 -8 119 -26 138 -64 8
-15 26 -92 41 -172 24 -135 85 -399 141 -620 28 -107 76 -217 98 -226 25 -10
103 34 136 76 105 133 125 370 75 907 -14 150 -25 303 -23 341 l3 67 54 -50
c84 -79 177 -210 238 -335 30 -63 77 -160 104 -216 43 -91 126 -204 149 -204
16 0 58 105 64 160 9 82 -10 360 -34 480 -11 58 -47 203 -80 324 -33 120 -63
247 -66 282 -6 58 -5 64 12 64 34 0 121 -47 212 -114 92 -67 146 -96 181 -96
28 0 34 41 21 141 -17 143 -48 233 -156 457 -106 219 -126 280 -132 400 l-5
103 39 82 c43 92 55 178 34 243 -21 62 -102 131 -239 204 -68 36 -141 81 -163
100 -48 42 -46 59 10 122 61 67 101 81 312 105 76 9 89 29 44 66 -44 36 -87
79 -120 122 l-30 39 -85 -2 c-215 -6 -504 -43 -581 -76 -43 -19 -164 -32 -203
-22 l-27 7 7 154 c8 160 -3 255 -30 255 -7 0 -29 -19 -50 -41z"
        />
        <path
          d="M7766 15158 c-138 -30 -225 -198 -167 -323 27 -57 71 -93 201 -164
63 -35 121 -69 129 -76 11 -11 7 -26 -23 -93 -56 -124 -48 -180 47 -315 24
-34 111 -156 192 -272 335 -474 425 -623 545 -898 48 -111 102 -235 121 -276
l33 -74 12 30 c20 48 44 233 44 338 0 239 -116 576 -322 933 -68 119 -148 280
-148 299 0 7 4 13 8 13 15 0 104 -61 160 -108 56 -48 332 -353 600 -664 200
-232 306 -338 310 -310 11 73 -295 729 -452 967 -194 295 -434 520 -841 788
-271 178 -368 222 -449 205z"
        />
        <path
          d="M8038 8182 c-19 -21 -79 -112 -133 -203 -192 -320 -319 -494 -481
-654 -159 -157 -327 -267 -637 -415 l-157 -75 0 -751 0 -752 87 -68 c272 -216
365 -452 350 -889 -7 -202 -23 -305 -77 -480 -57 -187 -68 -272 -74 -585 -7
-307 -15 -360 -72 -476 -74 -150 -188 -266 -466 -473 -119 -89 -152 -119 -262
-250 -192 -226 -322 -342 -501 -445 -173 -99 -488 -203 -645 -213 -57 -3 -100
-12 -130 -26 -118 -56 -614 7 -874 109 -187 74 -373 185 -538 324 -170 141
-295 295 -425 520 -107 186 -229 367 -373 550 -66 85 -135 184 -153 220 -31
62 -32 69 -32 185 0 104 5 138 35 255 31 120 35 150 35 265 0 128 -1 134 -56
330 -64 227 -84 353 -74 464 16 173 54 297 160 521 109 232 141 360 170 685 5
59 -38 696 -61 903 -7 61 5 55 -178 108 -217 63 -395 165 -608 351 -163 142
-307 319 -532 655 -77 115 -130 185 -151 198 -61 38 -62 36 -69 -132 -9 -242
-16 -535 -27 -1238 -5 -360 -18 -911 -29 -1225 -11 -323 -20 -774 -20 -1041 0
-463 -5 -594 -21 -594 -18 0 -40 64 -63 177 -13 65 -31 135 -39 156 l-17 38
-57 -6 c-111 -14 -210 -67 -276 -148 -61 -74 -114 -185 -131 -272 -8 -44 -19
-100 -25 -125 -11 -49 -8 -117 16 -345 13 -132 27 -191 108 -485 130 -465 168
-577 271 -782 147 -293 482 -762 719 -1007 185 -190 385 -343 650 -496 195
-113 500 -264 565 -280 36 -9 101 -27 144 -41 44 -14 166 -43 270 -64 105 -22
207 -47 226 -55 41 -17 324 -59 530 -77 99 -9 334 -13 740 -13 646 0 748 6
973 60 56 13 248 58 427 100 343 80 435 113 695 242 828 415 1502 1180 1774
2016 80 246 148 536 192 823 27 171 33 504 10 594 -30 124 -135 246 -255 295
l-38 16 6 -153 c7 -139 -3 -291 -20 -307 -3 -3 -22 4 -42 15 -53 31 -61 62
-62 259 0 94 -5 224 -10 290 -5 66 -14 237 -20 380 -5 143 -17 357 -25 475 -9
118 -40 746 -70 1395 -30 649 -59 1261 -65 1360 -11 193 -27 332 -40 340 -4 3
-23 -12 -42 -33z"
        />
      </g>
    </svg>
  );
};
