import { ReferrerStatusType } from "@/types";
import { getReferrerStatusText } from "@/utils";

type Props = {
  status: ReferrerStatusType;
};

const { BEGINNER, ADVANCED, MASTER } = ReferrerStatusType;

export const ReferrerStatusItem: React.FC<Props> = ({ status }) => {
  const statusText = getReferrerStatusText(status);

  return (
    <div className="theme-border flex-grow rounded-md px-6 py-4 bg-base-100 shadow">
      <div className="flex justify-between items-center gap-10 ">
        <span className="flex items-center gap-1">
          <span>Реферер статус:</span>
        </span>
        <span className="font-medium link-primary underline">{statusText}</span>
      </div>
      <div className="flex justify-between items-center gap-10 mt-2">
        <span>Реферальный процент:</span>
        {status === BEGINNER ? (
          <span className="badge badge-info">3%</span>
        ) : status === ADVANCED ? (
          <span className="badge badge-accent">5%</span>
        ) : status === MASTER ? (
          <span className="badge badge-success">7%</span>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
