import { IAppState, RootState } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const drawerState = localStorage.getItem("drawer");

const initialState: IAppState = {
  theme: "light",
  mainDrawerIsOpen: drawerState ? Boolean(parseInt(drawerState)) : true,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    changeTheme: (state, action: PayloadAction<"light" | "dark">) => {
      state.theme = action.payload;
    },
    toggleMainDrawer: (state) => {
      state.mainDrawerIsOpen = !state.mainDrawerIsOpen;
      localStorage.setItem("drawer", state.mainDrawerIsOpen ? "1" : "0");
    },
  },
});

export default appSlice.reducer;
export const { changeTheme, toggleMainDrawer } = appSlice.actions;
export const app = (state: RootState) => state.app;
