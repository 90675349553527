export const StatItem: React.FC<{
  count: number | undefined;
  title: string;
  postfix?: string;
}> = ({ count, title, postfix }) => {
  return (
    <div className="stats theme-border shadow rounded-md w-full xl:w-auto">
      <div className="stat">
        <div className="stat-title">{title}</div>
        <div className="stat-value text-3xl">
          {count !== undefined ? count : ""}
          {postfix && (
            <span className="font-medium text-xl pl-2">{postfix}</span>
          )}
        </div>
      </div>
    </div>
  );
};
