import { NotificationManager as notify } from "react-notifications";

import { DatePicker } from "@/components/DatePicker/DatePicker";

import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";

import { checkDatesDiff, compareDates } from "@/utils";
import {
  registrations,
  setRegsStartDate,
  setRegsEndDate,
} from "@/redux/features/registrationsSlice";

export const SelectRegistrationDates = () => {
  const { startDate, endDate } = useAppSelector(registrations);
  const dispatch = useAppDispatch();

  const getDateString = (date: Date) => {
    return `${date?.getFullYear()}/${date?.getMonth() + 1}/${date?.getDate()}`;
  };

  const startDateHandler = (date: Date | null) => {
    if (endDate && date) {
      const res = compareDates(getDateString(date), endDate);
      if (!res) return notify.error("Ошибочно выбрана дата");

      const dif = checkDatesDiff(getDateString(date), endDate, 93);
      if (!dif)
        return notify.error(
          "Максимально допустимый период для выборки - 3 месяца"
        );
    }
    dispatch(setRegsStartDate(date ? getDateString(date) : null));
  };

  const endDateHandler = (date: Date | null) => {
    if (startDate && date) {
      const res = compareDates(startDate, getDateString(date));
      if (!res) return notify.error("Ошибочно выбрана дата");

      const dif = checkDatesDiff(startDate, getDateString(date), 93);
      if (!dif)
        return notify.error(
          "Максимально допустимый период для выборки - 3 месяца"
        );
    }
    dispatch(setRegsEndDate(date ? getDateString(date) : null));
  };

  const clearDates = () => {
    dispatch(setRegsStartDate(null));
    dispatch(setRegsEndDate(null));
  };

  return (
    <div className="bg-base-100 flex-grow theme-border rounded-md shadow px-6 py-4 md:flex gap-5 md:items-end">
      <div>
        <label className="label">
          <span className="label-text">Начальная дата</span>
        </label>
        <DatePicker
          date={startDate ? new Date(startDate) : null}
          dateHandler={startDateHandler}
        />
      </div>
      <div>
        <label className="label">
          <span className="label-text">Конечная дата</span>
        </label>
        <DatePicker
          date={endDate ? new Date(endDate) : null}
          dateHandler={endDateHandler}
        />
      </div>
      <div>
        <button
          onClick={clearDates}
          className="btn w-full md:w-[100px] md:mt-0 mt-5 btn-error btn-sm lowercase"
        >
          сбросить
        </button>
      </div>
    </div>
  );
};
