import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { tokenLSKey } from "@/constants";
import { IOffersResponse, IOfferWithContent } from "@/types";

export const offersAPI = createApi({
  reducerPath: "offersAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/offers/",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["Offers"],
  endpoints: (builder) => ({
    getAllOffers: builder.query<
      IOffersResponse,
      {
        offset: number;
        limit: number;
        cityId: number | undefined;
        categoryId: number | undefined;
        status: string | undefined;
      } | null
    >({
      query: (params) => ({
        url: "",
        method: "GET",
        params: {
          offset: params?.offset,
          limit: params?.limit,
          cityId: params?.cityId,
          categoryId: params?.categoryId,
          status: params?.status,
        },
      }),
      providesTags: (result) => ["Offers"],
    }),

    getOfferById: builder.query<IOfferWithContent, { id: number }>({
      query: ({ id }) => ({
        url: `${id}`,
        method: "GET",
      }),
      providesTags: (result) => ["Offers"],
    }),
  }),
});

export const { useGetAllOffersQuery, useGetOfferByIdQuery } = offersAPI;
