import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState, ICuratorState, LegalInformation } from '@/types';

const initialState: ICuratorState = {
  id: null,
  login: null,
  name: null,
  isAuth: false,
  bankCards: [],
  createdAt: null,
  balance: null,
  workTelegram: null,
  workPhone: null,
  legalInformation: null,
};

export const curatorSlice = createSlice({
  initialState,
  name: 'curator',
  reducers: {
    logout: () => initialState,
    setCurator: (state, action: PayloadAction<any>) => {
      if (!action.payload.isBanned) {
        state.isAuth = true;
        state.id = action.payload.id;
        state.login = action.payload.login;
        state.name = action.payload.name;
        state.bankCards = action.payload.bankCards;
        state.createdAt = action.payload.createdAt;
        state.balance = action.payload.balance;
        state.workTelegram = action.payload.workTelegram;
        state.workPhone = action.payload.workPhone;
        state.legalInformation = action.payload.legalInformation;
      }
    },
    removeFromBalance: (state, action: PayloadAction<number>) => {
      if (state.balance) {
        state.balance.balance -= action.payload;
      }
    },

    patchLegalInformation: (state, action: PayloadAction<LegalInformation>) => {
      state.legalInformation = action.payload;
    },
  },
});

export default curatorSlice.reducer;

export const { logout, setCurator, removeFromBalance, patchLegalInformation } =
  curatorSlice.actions;
export const curator = (state: RootState) => state.curator;
