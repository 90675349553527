import { IRegistrationsState, RootState } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IRegistrationsState = {
  startDate: null,
  endDate: null,
};

export const registrationsSlice = createSlice({
  initialState,
  name: "registrations",
  reducers: {
    setRegsStartDate: (state, action: PayloadAction<string | null>) => {
      if (action.payload) {
        state.startDate = action.payload;
      } else {
        state.startDate = null;
      }
    },
    setRegsEndDate: (state, action: PayloadAction<string | null>) => {
      if (action.payload) {
        state.endDate = action.payload;
      } else {
        state.endDate = null;
      }
    },
  },
});

export default registrationsSlice.reducer;

export const { setRegsStartDate, setRegsEndDate } = registrationsSlice.actions;
export const registrations = (state: RootState) => state.registrations;
