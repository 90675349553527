import { ILead, LeadStatus } from '@/types';
import { FC, useState } from 'react';
import { TelephonyCompanyCeil } from '../TelephonyCompanyCeil/TelephonyCompanyCeil';
import { useAppSelector } from '@/hooks/useRedux';
import { LeadStatusBadge } from '../LeadStatusBadge/LeadStatusBadge';
import { webmasterLeads } from '@/redux/features/WebmasterLeadsSlice';
import { Link } from 'react-router-dom';
import { routing } from '@/constants';

type Props = {
  data: ILead;
  type: 'all' | 'webmaster';
};

export const LeadsMobCollapse: FC<Props> = ({ data, type }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { telephony } = useAppSelector(webmasterLeads);

  return (
    <div
      className={`mob-collapse no-arrow bg-base-100 theme-border last:border-b-0 collapse ${
        isOpen ? 'collapse-open' : 'collapse-close'
      }`}
    >
      <div
        className="collapse-title cursor-pointer min-h-[auto] py-2 px-2 min-[360px]:px-5"
        onClick={() => setIsOpen((p) => !p)}
      >
        <div className="flex gap-2 items-center flex-wrap justify-between">
          <div className="mr-auto text-sm">
            {new Date(data.createdAt)
              .toLocaleDateString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit',
              })
              .replace(',', '')}
          </div>
          <LeadStatusBadge
            comment={data.comment}
            status={data.status}
            size="badge-sm"
          />
        </div>
      </div>

      <div className="collapse-content px-2 min-[360px]:px-5">
        <div>
          <hr className={`divider h-[1px] ${isOpen && 'mt-0'}`} />
          <div className="flex flex-col items-start gap-1">
            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">ID:</span>
              </label>
              <div>{data.amoId}</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Дата:</span>
              </label>
              <div>
                {new Date(data.createdAt)
                  .toLocaleDateString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                  .replace(',', '')}
              </div>
            </div>

            {type === 'all' && (
              <div className="flex items-center gap-2 flex-wrap">
                <label className="label p-0">
                  <span className="label-text font-medium">Вебмастер:</span>
                </label>
                <div>
                  <Link
                    className="link-primary"
                    to={`/${routing.CABINET}/${routing.WEBMASTERS}/${data.webmasterId}`}
                  >
                    {data.webmasterLogin}
                  </Link>
                </div>
              </div>
            )}

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Категория:</span>
              </label>
              <div>{data.categoryName || '----'}</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Город:</span>
              </label>
              <div>{data.cityName || '----'}</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Адрес:</span>
              </label>
              <div>{data.address || '----'}</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Телефон клиента:</span>
              </label>
              <a className="link-primary" href={`tel:${data.leadPhone}`}>
                {data.leadPhone}
              </a>
            </div>

            {type === 'all' ? (
              <div className="flex items-center gap-2 flex-wrap">
                <label className="label p-0">
                  <span className="label-text font-medium">Телефония:</span>
                </label>
                <div>
                  {data.trackingPhone === 'p_manually'
                    ? 'Вручную (Частное лицо)'
                    : data.trackingPhone === 'c_manually'
                    ? 'Вручную (Компания)'
                    : data.trackingPhone === 'c_api'
                    ? 'API (Компания)'
                    : data.trackingPhone === 'p_api'
                    ? 'API (Частное лицо)'
                    : data.trackingPhone}
                </div>
              </div>
            ) : (
              <div className="flex items-center gap-2 flex-wrap">
                <label className="label p-0">
                  <span className="label-text font-medium">
                    Рекл. кампания:
                  </span>
                </label>
                <div>
                  <TelephonyCompanyCeil
                    number={data.trackingPhone}
                    telephony={telephony}
                    tag="div"
                  />
                </div>
              </div>
            )}

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Сумма:</span>
              </label>
              <div>
                {data.sum
                  ? data.status === LeadStatus.APPROVED ||
                    data.status === LeadStatus.IN_WORK
                    ? data.sum + ' K'
                    : '----'
                  : '----'}
              </div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Комиссия:</span>
              </label>
              <div>
                {data.sum
                  ? data.status === LeadStatus.APPROVED ||
                    data.status === LeadStatus.IN_WORK
                    ? Math.round((data.sum / 100) * 10) + ' K'
                    : '----'
                  : '----'}
              </div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Статус:</span>
              </label>
              <div>
                <LeadStatusBadge comment={data.comment} status={data.status} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
