import { IMoneyMovementRecord, MoneyMovementType } from '@/types';
import { getFinancesStatusIcon } from '@/utils';
import { FC, useState } from 'react';
import { MoneyMovementStatusBadge } from '../MoneyMovementStatusBadge/MoneyMovementStatusBadge';

type Props = {
  data: IMoneyMovementRecord;
};

export const MoneyMovementMobCollapse: FC<Props> = ({ data }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div
      className={`mob-collapse bg-base-100 theme-border last:border-b-0 collapse ${
        isOpen ? 'collapse-open' : ''
      }`}
    >
      <div
        className='collapse-title cursor-pointer min-h-[auto] py-2 px-2 min-[360px]:px-5'
        onClick={() => setIsOpen((p) => !p)}
      >
        <div className='flex gap-2 items-center flex-wrap justify-between'>
          <div className='flex flex-col gap-1'>
            <div className='text-sm'>
              {new Date(data.createdAt)
                .toLocaleDateString(navigator.language, {
                  hour: '2-digit',
                  minute: '2-digit',
                })
                .replace(',', '')}
            </div>
            <div className='text-sm'>
              {data.type === MoneyMovementType.CURATOR_INCOME
                ? 'Выплата за лид'
                : data.type === MoneyMovementType.PAYMENT && 'Вывод средств'}
            </div>
          </div>
          {getFinancesStatusIcon(data.status)}
        </div>
      </div>

      <div className='collapse-content px-2 min-[360px]:px-5'>
        <div>
          <hr className={`divider h-[1px] ${isOpen && 'mt-0'}`} />
          <div className='flex flex-col items-start gap-1'>
            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Дата:</span>
              </label>
              <div>
                {new Date(data.createdAt)
                  .toLocaleDateString(navigator.language, {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                  .replace(',', '')}
              </div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Описание:</span>
              </label>
              <div>{data.description}</div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Сумма:</span>
              </label>
              <div>
                {' '}
                {data.type === MoneyMovementType.CURATOR_INCOME
                  ? `${data.sum} K`
                  : `-${Math.abs(data.sum)} K`}
              </div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Тип:</span>
              </label>
              <div>
                {data.type === MoneyMovementType.CURATOR_INCOME
                  ? 'Выплата за лид'
                  : data.type === MoneyMovementType.PAYMENT && 'Вывод средств'}
              </div>
            </div>

            <div className='flex items-center gap-2 flex-wrap'>
              <label className='label p-0'>
                <span className='label-text font-medium'>Статус:</span>
              </label>
              <div>
                <MoneyMovementStatusBadge status={data.status} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
