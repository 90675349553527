import {
  FormEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  LeadsMobCollapse,
  LeadsStatTable,
  LeadsTable,
  Pagination,
  Search,
  WebmasterLeadsFiltersPanel,
} from '@/components';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import {
  useGetLeadsSearchQuery,
  useGetWebmasterLeadsQuery,
} from '@/redux/api/leadsAPI';
import {
  webmasterLeads,
  setOffset,
} from '@/redux/features/WebmasterLeadsSlice';
import './WebmasterLeadsCollapse.css';

import { ILead } from '@/types';

type Props = {
  webmasterId: number;
};

export const WebmasterLeadsCollapse: React.FC<Props> = ({ webmasterId }) => {
  const ref: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [collapseIsOpen, setCollapseOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const [start, setStart] = useState<string>();
  const [y, setY] = useState<number | undefined>();
  const [end, setEnd] = useState<string>();
  const [isSearched, setSearched] = useState(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [leadsList, setLeadsList] = useState<
    { count: number; rows: ILead[] | [] } | undefined
  >(undefined);
  const dispatch = useAppDispatch();

  const {
    startDate,
    endDate,
    limit,
    categoryId,
    cityId,
    lossReason,
    status,
    offset,
    trackingPhone,
  } = useAppSelector(webmasterLeads);
  const { data, isLoading, isFetching } = useGetWebmasterLeadsQuery(
    {
      webmasterId,
      offset,
      limit,
      trackingPhone,
      categoryId,
      cityId,
      status,
      start,
      end,
      lossReason,
    },
    { skip: !collapseIsOpen && isSearched }
  );

  const {
    data: searchData,
    isLoading: searchIsLoading,
    isFetching: searchIsFetching,
  } = useGetLeadsSearchQuery(
    {
      query: searchValue,
      webmasterId,
    },
    {
      skip: !isSearched,
      refetchOnMountOrArgChange: true,
    }
  );

  const dataIsset = loading || !leadsList?.rows.length;

  const searchChangeHandler = (value: string) => {
    const regex = /^[a-zA-Zа-яА-Я0-9]*$/;

    if (regex.test(value)) {
      setSearchValue(value);
    }
  };

  const searchFormHandler = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (searchValue.length >= 3) {
      setSearched(true);
    }
  };

  const searchDeleteHandler = () => {
    if (setSearched) {
      setSearched(false);
    }

    setSearchValue('');
  };

  useEffect(() => {
    if (startDate && endDate) {
      dispatch(setOffset(0));
      setStart(startDate);
      setEnd(endDate);
    } else {
      dispatch(setOffset(0));
      setStart(undefined);
      setEnd(undefined);
    }
  }, [startDate, endDate, dispatch]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isFetching || isLoading || searchIsFetching || searchIsLoading) {
      setloading(true);
    } else if (!isFetching && !isLoading) {
      timeout = setTimeout(() => setloading(false), 200);
    }
    return () => clearTimeout(timeout);
  }, [isFetching, isLoading, searchIsFetching, searchIsLoading]);

  useEffect(() => {
    const getPosition = () => setY(ref.current?.offsetTop);
    getPosition();
    window.addEventListener('resize', getPosition);
    return () => window.removeEventListener('resize', getPosition);
  }, []);

  useEffect(() => {
    if (isSearched) {
      dispatch(setOffset(0));
    }
  }, [dispatch, isSearched]);

  useEffect(() => {
    if (!isSearched && searchValue.length < 3)
      setLeadsList({ count: data?.total || 0, rows: data?.leads || [] });
  }, [isSearched, data, searchValue]);

  useEffect(() => {
    if (isSearched) setLeadsList(searchData);
  }, [isSearched, searchData]);

  return (
    <div
      ref={ref}
      className={`collapse collapse-arrow ${
        collapseIsOpen ? 'collapse-open' : 'shadow'
      }  bg-base-100 rounded-md `}
    >
      <div
        onClick={() => setCollapseOpen(!collapseIsOpen)}
        className='collapse-title shadow cursor-pointer text-xl font-medium  theme-border rounded-md'
      >
        Лиды
      </div>
      <div className='collapse-content p-0 bg-base-200'>
        <div className='mt-5'>
          <WebmasterLeadsFiltersPanel />

          <div className='bg-base-100 theme-border shadow rounded-md px-6 py-4 flex mb-5 gap-5 flex-col md:flex-row justify-between items-center min-h-16'>
            <Search
              value={searchValue}
              onChange={searchChangeHandler}
              setSearched={setSearched}
              formHandler={searchFormHandler}
              deleteHandler={searchDeleteHandler}
            />
            {data && !isSearched && searchValue.length < 3 && (
              <div>
                <LeadsStatTable
                  type='webmaster'
                  status={status}
                  sum={data?.sum || 0}
                  total={data?.total || 0}
                  totalWithParams={data?.totalWithParams || 0}
                />
              </div>
            )}
          </div>

          <div
            className={`webmaster-leads-list theme-border mb-5  ${
              dataIsset ? 'empty' : ''
            } flex flex-col`}
          >
            {loading ? (
              <span className='btn btn-square loading'></span>
            ) : (
              <>
                {leadsList && leadsList.rows.length === 0 && (
                  <div className='text-2xl no-data-text font-thin w-full block my-auto text-center'>
                    Пока что лидов нет
                  </div>
                )}

                {leadsList && leadsList.rows.length > 0 && (
                  <>
                    <div className='w-full hidden md:block'>
                      <LeadsTable
                        leads={leadsList?.rows || []}
                        type='webmaster'
                      />
                    </div>

                    <div className='block md:hidden rounded-md overflow-hidden shadow t-border'>
                      {leadsList &&
                        leadsList.rows.map((el) => {
                          return (
                            <LeadsMobCollapse
                              key={el.amoId}
                              data={el}
                              type='webmaster'
                            />
                          );
                        })}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>

        {!isSearched && searchValue.length < 3 && (
          <>
            {!loading && data && leadsList?.rows.length ? (
              <Pagination
                callback={(page: number) =>
                  dispatch(setOffset((page - 1) * limit))
                }
                total={data.totalWithParams ? data.totalWithParams : data.total}
                offset={data.offset ? data.offset : 0}
                step={limit}
                top={y}
              />
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </div>
  );
};
