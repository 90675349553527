import { WebmasterTableRow } from '@/components';
import { IWebmaster } from '@/types';

type props = {
  webmasters: IWebmaster[];
};

export const WebmastersTable: React.FC<props> = ({ webmasters }) => {
  return (
    <div className="overflow-x-auto ">
      <table className="table table-compact w-full">
        <thead>
          <tr>
            <th>ID</th>
            <th>Логин</th>
            <th></th>
            <th>Добавлен</th>
            <th>Рекл. кампании</th>
            <th>Баланс</th>
            <th>Холд</th>
            <th>Заработал</th>
            <th>Комиссионные</th>
            <th>Телеграм</th>
            <th>Телефон</th>
            <th>Статус</th>
            <th>юр. статус</th>
          </tr>
        </thead>
        <tbody>
          {webmasters &&
            webmasters.map((webmaster) => (
              <WebmasterTableRow key={webmaster.id} webmaster={webmaster} />
            ))}
        </tbody>
      </table>
    </div>
  );
};
