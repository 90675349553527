import { NotificationManager as notify } from "react-notifications";
import { DatePicker } from "@/components/DatePicker/DatePicker";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import {
  finances,
  setFinancesEndDate,
  setFinancesStartDate,
} from "@/redux/features/financesSlice";
import { compareDates } from "@/utils";

export const SelectMoneymoventDates = () => {
  const { startDate, endDate } = useAppSelector(finances);
  const dispatch = useAppDispatch();

  const getDateString = (date: Date) => {
    return `${date?.getFullYear()}/${date?.getMonth() + 1}/${date?.getDate()}`;
  };

  const startDateHandler = (date: Date | null) => {
    if (endDate && date) {
      const res = compareDates(getDateString(date), endDate);
      if (!res) return notify.error("Ошибочно выбрана дата");
    }
    dispatch(setFinancesStartDate(date ? getDateString(date) : null));
  };

  const endDateHandler = (date: Date | null) => {
    if (startDate && date) {
      const res = compareDates(startDate, getDateString(date));
      if (!res) return notify.error("Ошибочно выбрана дата");
    }
    dispatch(setFinancesEndDate(date ? getDateString(date) : null));
  };

  const clearDates = () => {
    dispatch(setFinancesStartDate(null));
    dispatch(setFinancesEndDate(null));
  };

  return (
    <div className="bg-base-100 flex-grow theme-border rounded-md shadow px-6 py-4 md:flex gap-5  md:items-end">
      <div>
        <label className="label">
          <span className="label-text">Начальная дата</span>
        </label>
        <DatePicker
          date={startDate ? new Date(startDate) : null}
          dateHandler={startDateHandler}
        />
      </div>
      <div>
        <label className="label">
          <span className="label-text">Конечная дата</span>
        </label>
        <DatePicker
          date={endDate ? new Date(endDate) : null}
          dateHandler={endDateHandler}
        />
      </div>
      <div>
        <button
          onClick={clearDates}
          className="btn w-full md:w-[100px] md:mt-0 mt-5 btn-error btn-sm lowercase"
        >
          сбросить
        </button>
      </div>
    </div>
  );
};
