import { useGetAllCategoriesQuery } from "@/redux/api/offerCategoriesAPI";
import { useEffect, useState } from "react";

type Props = {
  valueHandler: (categoryId: number) => void;
  selectedId?: number;
  noCategory?: boolean;
};

export const SelectCategory: React.FC<Props> = ({
  valueHandler,
  selectedId,
  noCategory,
}) => {
  const { data, isFetching, isLoading } = useGetAllCategoriesQuery(null);
  const loading = isFetching || isLoading;

  const [selected, setSelected] = useState<number>();

  const changeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelected(parseInt(event.target.value));
    valueHandler(parseInt(event.target.value));
  };

  useEffect(() => {
    if (selectedId) setSelected(selectedId);
  }, [selectedId]);

  return (
    <>
      {loading ? (
        ""
      ) : (
        <>
          {data && data.length ? (
            <>
              <label className="label">
                <span className="label-text">Категория</span>
              </label>
              <select
                className="select select-sm select-bordered w-full lg:max-w-xs "
                value={selected}
                onChange={changeSelect}
              >
                {noCategory ? (
                  <option value={0}>Все категории </option>
                ) : (
                  <option disabled value="">
                    Выберите категорию
                  </option>
                )}
                {data?.map((category) => {
                  return (
                    <option value={category.id} key={category.id}>
                      {category.name}
                    </option>
                  );
                })}
              </select>
            </>
          ) : (
            <select className="select select-sm w-full select-bordered max-w-xs ">
              <option disabled selected>
                Нет категорий
              </option>
            </select>
          )}
        </>
      )}
    </>
  );
};
