import { IBankCard, LeadStatus, ReferrerStatusType } from '@/types';

export const validateTelegram = (telegram: string) => {
  const tgReqex = /^@?[A-Za-z\d_]{5,32}$/;
  if (!tgReqex.test(telegram)) return 'Некорректный телеграм';
};

export const validatePhone = (phone: string) => {
  const phoneReqex = /^\+?[\d ()-]{8,20}$/;
  if (!phoneReqex.test(phone)) return 'Некорректный телефон';
};

export const validatePassword = (password: string) => {
  if (!password) return 'Введите пароль';
  if (password.length < 6 || password.length > 30) {
    return 'Пароль должен быть не меньше 6 и не больше 30 символов';
  }
};

export const validateNumbers = (data: string): boolean => {
  const numberReqex = /^\d+$/;

  if (!numberReqex.test(data)) return true;
  return false;
};

export const validateBankCards = (cards: IBankCard[]) => {
  let error: string | null = null;
  if (!cards.length) return;

  const cardNumberReqEx = /^[0-9\s]{12,20}$/;
  for (let i = 0; i < cards.length; i++) {
    const card = cards[i];
    if (card.bank === 'none') {
      error = `Укажите банк ${cards.length > 1 ? `в ${i + 1} карте` : ''}`;
      break;
    }
    if (card.bank === 'custom' && !card.customBank?.trimEnd().trimStart()) {
      error = `Укажите название банка ${
        cards.length > 1 ? `в ${i + 1} карте` : ''
      }`;
      break;
    }
    if (!card.number) {
      error = `Укажите номер ${
        cards.length > 1 ? `в ${i + 1} карте` : 'карты'
      }`;
      break;
    }
    if (!cardNumberReqEx.test(card.number.trim())) {
      error = `Некорректный номер ${
        cards.length > 1 ? `в ${i + 1} карте` : 'карты'
      }`;
      break;
    }
    if (!card.fio) {
      error = `Укажите ФИО ${cards.length > 1 ? `в ${i + 1} карте` : 'карты'}`;
      break;
    }
  }

  return error;
};

export const compareDates = (start: string, end: string) => {
  const startDateArr = start.split('/');
  const s = new Date(
    parseInt(startDateArr[0]),
    parseInt(startDateArr[1]) - 1,
    parseInt(startDateArr[2])
  );

  const endDateArr = end.split('/');
  const e = new Date(
    parseInt(endDateArr[0]),
    parseInt(endDateArr[1]) - 1,
    parseInt(endDateArr[2]),
    23,
    59,
    59
  );

  if (s.getTime() > e.getTime()) return false;
  return true;
};

export const checkDatesDiff = (start: string, end: string, days: number) => {
  const startDateArr = start.split('/');
  const s = new Date(
    parseInt(startDateArr[0]),
    parseInt(startDateArr[1]) - 1,
    parseInt(startDateArr[2])
  );

  const endDateArr = end.split('/');
  const e = new Date(
    parseInt(endDateArr[0]),
    parseInt(endDateArr[1]) - 1,
    parseInt(endDateArr[2]),
    23,
    59,
    59
  );

  const diff = Math.abs(e.getTime() - s.getTime());

  if (Math.ceil(diff / (1000 * 3600 * 24)) > days) return false;
  return true;
};

export const getStatusText = (status: LeadStatus) => {
  switch (status) {
    case LeadStatus.LEAD:
      return 'В обработке';
    case LeadStatus.IN_WORK:
      return 'В работе';
    case LeadStatus.APPROVED:
      return 'Подтвержден';
    case LeadStatus.REJECTED:
      return 'Отклонен';
  }
};

export const getLossReasonText = (comment: string | null) => {
  if (!comment) return null;

  switch (comment) {
    case 'Пропала связь с клиентом':
      return 'Пропала связь';
    case 'Неплатёжеспособное лицо':
      return 'Неплатёж. лицо';
    default:
      return comment;
  }
};
export const getReferrerStatusText = (status: ReferrerStatusType) => {
  switch (status) {
    case ReferrerStatusType.BEGINNER:
      return 'Львенок';
    case ReferrerStatusType.ADVANCED:
      return 'Лев на минималках';
    case ReferrerStatusType.MASTER:
      return 'Увереный лев';
  }
};

export * from './getFinancesStatusIcon';
