import { useAppDispatch } from "@/hooks/useRedux";
import {
  SelectCategory,
  SelectCity,
  SelectPhone,
  SelectStatus,
  SelectWebmasterLeadDates,
} from "@/components";
import {
  setCityId,
  setLossReason,
  setStatus,
  setCategoryId,
  setOffset,
  setTrackingPhone,
} from "@/redux/features/WebmasterLeadsSlice";

export const WebmasterLeadsFiltersPanel = () => {
  const dispatch = useAppDispatch();

  const categoryIdChangeHandler = (id: number) => {
    dispatch(setOffset(0));
    dispatch(setCategoryId(id ? id : undefined));
  };
  const cityIdChangeHandler = (id: number) => {
    dispatch(setOffset(0));
    dispatch(setCityId(id ? id : undefined));
  };

  const statusChangeHandler = (status: string) => {
    dispatch(setOffset(0));
    if (status === "all") {
      dispatch(setLossReason(undefined));
      return dispatch(setStatus(undefined));
    }
    if (status.split("|").length === 2) {
      dispatch(setStatus(status.split("|")[0]));
      dispatch(setLossReason(status.split("|")[1]));
    } else {
      dispatch(setStatus(status));
      dispatch(setLossReason(undefined));
    }
  };

  const phoneChangeHandler = (phone: string) => {
    dispatch(setOffset(0));
    if (phone === "all") return dispatch(setTrackingPhone(undefined));
    dispatch(setTrackingPhone(phone));
  };

  return (
    <div className="flex xl:flex-row flex-col mb-5 gap-5 ">
      <div className="bg-base-100 shadow offers-top-panel rounded-md px-6 py-4 flex  gap-5 flex-grow">
        <div className="flex md:gap-5 flex-col md:flex-row filters-panel w-full md:items-end">
          <div>
            <SelectCategory
              valueHandler={categoryIdChangeHandler}
              noCategory={true}
            />
          </div>
          <div>
            <SelectCity noCategory={true} valueHandler={cityIdChangeHandler} />
          </div>
          <div>
            <SelectPhone valueHandler={phoneChangeHandler} />
          </div>

          <div>
            <SelectStatus valueHandler={statusChangeHandler} />
          </div>
        </div>
      </div>

      <SelectWebmasterLeadDates />
    </div>
  );
};
