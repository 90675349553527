import { useAppSelector } from '@/hooks/useRedux';
import { curator } from '@/redux/features/curatorSlice';
import { LeadStatus } from '@/types';

type Props = {
  status: string | undefined;
  total: number;
  totalWithParams: number | null;
  sum: number | null;
  type: 'all' | 'webmaster';
};

export const LeadsStatTable: React.FC<Props> = ({
  status,
  sum,
  total,
  totalWithParams,
  type,
}) => {
  const { balance } = useAppSelector(curator);
  console.log(totalWithParams);

  return (
    <div className="flex justify-between pr-2  w-full">
      {totalWithParams === null ? (
        <>
          {type === 'all' ? (
            <div className="flex justify-between flex-wrap gap-5 w-full">
              <div className="flex gap-5">
                <div>
                  Всего заработано комиссионных: <b>{balance?.totalEarned} K</b>
                </div>
                <div>
                  Всего лидов: <b>{total}</b>
                </div>
              </div>
            </div>
          ) : (
            type === 'webmaster' && (
              <>
                <div></div>
                <div></div>
              </>
            )
          )}
        </>
      ) : (
        <>
          <div className="flex gap-5">
            {sum && status ? (
              <div>
                {status === LeadStatus.APPROVED && (
                  <>
                    Заработано комиссионных:{' '}
                    <b>{Math.round((sum / 100) * 10)} K</b>
                  </>
                )}
                {status === LeadStatus.IN_WORK && (
                  <>
                    Комиссионных в работе:{' '}
                    <b>{Math.round((sum / 100) * 10)} K</b>
                  </>
                )}
              </div>
            ) : (
              ''
            )}

            {Boolean(totalWithParams) && (
              <div>
                Кол-во лидов: <b>{totalWithParams}</b>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
