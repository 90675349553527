import { useState } from "react";

type Props = {
  valueHandler: (status: string) => void;
};

export const OfferStatusSelect: React.FC<Props> = ({ valueHandler }) => {
  const [selected, setSelected] = useState<string>("all");

  const changeSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const status = event.target.value;
    setSelected(status);
    valueHandler(status);
  };

  return (
    <>
      <label className="label">
        <span className="label-text">Статус</span>
      </label>
      <select
        className="select select-sm select-bordered w-full lg:max-w-xs "
        value={selected}
        onChange={changeSelect}
      >
        <option value="all">Все Статусы </option>
        <option value="active">Активные</option>
        <option value="paused">Остановленные </option>
      </select>
    </>
  );
};
