import { useState } from "react";

import "./WebmasterAnalitycsCollapse.css";

type Props = {
  webmasterId: number;
};

export const WebmasterAnalitycsCollapse: React.FC<Props> = ({
  webmasterId,
}) => {
  const [collapseIsOpen, setCollapseOpen] = useState(false);

  return (
    <div
      className={`collapse collapse-arrow ${
        collapseIsOpen ? "collapse-open" : "shadow"
      }  bg-base-100 rounded-md mb-5`}
    >
      <div
        onClick={() => setCollapseOpen(!collapseIsOpen)}
        className="collapse-title shadow cursor-pointer text-xl font-medium  theme-border rounded-md"
      >
        Аналитика
      </div>
      <div className="collapse-content p-0 bg-base-200">
        <div className="mt-5">
          <div className="text-2xl no-data-text font-thin text-center">
            Скоро...
          </div>
        </div>
      </div>
    </div>
  );
};
