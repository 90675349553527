import { IWebmasterBalance } from '@/types';

type Props = {
  balance: IWebmasterBalance;
};

export const WebmasterBalanceStatItems: React.FC<Props> = ({ balance }) => {
  return (
    <div className="stats rounded-md shadow theme-border">
      <div className="stat">
        <div className="stat-title">Баланс</div>
        <div className="stat-value">
          {balance.balance}
          <span className="font-medium text-xl pl-2">K</span>
        </div>
      </div>

      <div className="stat">
        <div className="stat-title">Xолд</div>
        <div className="stat-value">
          {balance.hold}
          <span className="font-medium text-xl pl-2">K</span>
        </div>
      </div>

      <div className="stat">
        <div className="stat-title">Заработал всего</div>
        <div className="stat-value">
          {balance.totalEarned}
          <span className="font-medium text-xl pl-2">K</span>
        </div>
      </div>
    </div>
  );
};
