import { rootReducer, setupStore } from '@/redux/store';
import { OutputData } from '@editorjs/editorjs';

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

export interface IEditorCore {
  destroy(): Promise<void>;
  clear(): Promise<void>;
  save(): Promise<OutputData>;
  render(data: OutputData): Promise<void>;
}

export interface IAppState {
  theme: 'light' | 'dark';
  mainDrawerIsOpen: boolean;
}

export interface ICuratorState {
  id: number | null;
  login: string | null;
  name: string | null;
  isAuth: boolean;
  bankCards: IBankCard[];
  createdAt: Date | null;
  balance: IBalance | null;
  workTelegram: string | null;
  workPhone: string | null;
  legalInformation: null | LegalInformation;
}

export interface IRegistrationsState {
  startDate: string | null;
  endDate: string | null;
}

export interface IWebmastersState {
  expandedRow: number | null;
  offset: number;
  limit: number;
  sort: UsersSortType;
}

export interface ILeadsState {
  startDate: string | null;
  endDate: string | null;
}

export interface IWebmasterLeadsState {
  limit: number;
  offset: number;
  startDate: string | null;
  endDate: string | null;
  categoryId: number | undefined;
  cityId: number | undefined;
  lossReason: string | undefined;
  status: string | undefined;
  trackingPhone: string | undefined;
  telephony: ITelephony[];
}

export interface ICurator {
  id: number;
  login: string;
  roles: string[];
  bankCards: IBankCard[];
  isBanned: boolean;
  createdAt: Date;
  updatedAt: Date;
  balance: IBalance;
  workPhone: string | null;
  workTelegram: string | null;
  legalInformation: null | LegalInformation;
}

export interface LegalInformation {
  id: number;
  name: string;
  bankBic: string;
  INN: string;
  checkingAccount: string;
  address: string;
  status: LegalInformationStatus;
}

export enum LegalInformationStatus {
  CONFIRMED = 'confirmed',
  REJECTED = 'rejected',
  MODERATION = 'moderation',
}

export interface IBalance {
  balance: number;
  totalEarned: number;
}
export interface IBankCard {
  bank: string;
  number: string;
  fio: string;
  customBank?: string;
}

export interface IOffer {
  id: number;
  name: string;
  leadPrice: null | number;
  companyLeadPrice: null | number;
  isPaused: boolean;
  cityId: number;
  categoryId: number;
  createdAt: Date;
  updatedAt: Date;
  city: ICity;
  category: IOfferCategory;
}

export interface IOfferWithContent extends IOffer {
  content: string;
}

export interface ICity {
  id: number;
  name: string;
}

export interface IOfferCategory {
  id: number;
  name: string;
  img: string;
}

export interface INews {
  id: number;
  title: string;
  visible: boolean;
  createdAt: Date;
  updatedAt: Date;
  content: string;
}

export interface INewsPrewiew extends Omit<INews, 'content'> {}

export enum UsersSortType {
  DATE_UP = 'date-up',
  DATE_DOWN = 'date-down',
  SUM_UP = 'sum-up',
  SUM_DOWN = 'sum-down',
}

export interface IGetWebmastersQuery {
  offset: number;
  limit: number;
  sort: UsersSortType | undefined;
}

export enum TelephonyType {
  PRIVATE = 'private',
  COMPANY = 'company',
}

export interface ITelephony {
  name: string;
  number: string;
  type: TelephonyType;
}

export interface IWebmasterBalance {
  id: number;
  balance: number;
  hold: number;
  totalEarned: number;
}

export enum ReferrerStatusType {
  BEGINNER = 'beginner',
  ADVANCED = 'advanced',
  MASTER = 'master',
}

export interface IWebmaster {
  id: number;
  login: string;
  roles: string[];
  createdAt: Date;
  telephony: ITelephony[];
  workPhone: string | null;
  workTelegram: string | null;
  bankCards: string[];
  isBanned: boolean;
  balance: IWebmasterBalance;
  notes: INote[];
  legalInformation: LegalInformation;
}

export interface ICuratorWebmaster extends Omit<IWebmaster, 'roles'> {
  refId: null;
  refName: null;
  referrerStatus: ReferrerStatusType;
  curatorId: number;
}

export interface IWebmastersResponse {
  total: number;
  offset: null | number;
  limit: null | number;
  webmasters: IWebmaster[];
}

export interface IVisit {
  date: string;
  visits: string;
  unique: string;
  registrations: string;
  success: string;
  reject: string;
  waiting: string;
}

export enum NoteColors {
  YELLOW = 'yellow',
  BLUE = 'blue',
  RED = 'red',
}

export interface INote {
  id: number;
  text: string;
  color: NoteColors;
  webmasterId: number;
  wrote: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IAddNote
  extends Omit<INote, 'id' | 'updatedAt' | 'createdAt'> {}

export interface INewsResponse {
  news: INewsPrewiew[];
  total: number;
  offset: number;
  limit: number;
}

export interface IOffersResponse {
  total: number;
  totalWithParams: number | null;
  offset: null | number;
  limit: null | number;
  categoryId: null | number;
  cityId: null | number;
  status: null | string;
  offers: IOffer[];
}

export interface IErrorResponse {
  data: { message: string };
}

export enum LossReasonText {
  NOT_VALID = 'Невалид',
  INSOLVENT_PERSON = 'Неплатёжеспособное лицо',
  LEAD_CANCEL = 'Отмена лида',
  NOT_CONFIRM = 'Не подтверждён',
  NOT_DIALING = 'Пропала связь с клиентом',
  SPAM = 'Спам',
  NOT_ACTIVE_OFFER = 'Неактив. оффер',
  NOT_ACTIVE_GEO = 'Неактив. ГЕО',
  CALL_CHECK = 'Проверка связи',
}

export enum LeadStatus {
  LEAD = 'lead',
  IN_WORK = 'in_work',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export interface ILead {
  id: number;
  amoId: string;
  status: LeadStatus;
  webmasterId: number | null;
  webmasterLogin: string | null;
  offerId: number | null;
  cityId: number | null;
  categoryId: number | null;
  sum: number | null;
  date: string | Date | null;
  comment: string | null;
  address: string | null;
  trackingPhone: string;
  hiddenLeadPhone: string;
  leadPhone: string;
  cityName: string | null;
  categoryName: string | null;
  data: JSON | null;
  createdAt: Date;
}

export interface ILeadsResponse {
  total: number;
  totalWithParams: number | null;
  offset: null | number;
  limit: null | number;
  categoryId: null | number;
  cityId: null | number;
  leads: ILead[];
  status: LeadStatus | null;
  sum: number | null;
}

export interface IFinancesState {
  startDate: string | null;
  endDate: string | null;
  paymentModalIsOpen: boolean;
}

export enum MoneyMovementType {
  PAYMENT = 'payment',
  CURATOR_INCOME = 'curatorIncome',
}

export enum MoneyMovementStatus {
  COMPLETED = 'completed',
  IN_PROCESS = 'inProcess',
  CANCELED = 'canceled',
}

export interface IMoneyMovementRecord {
  id: number;
  type: MoneyMovementType;
  description: string;
  status: MoneyMovementStatus;
  sum: number;
  webmasterId: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IMoneyMovementResponse {
  records: IMoneyMovementRecord[];
  total: number;
  totalWithParams: number | null;
  earnedFromPeriod: number | null;
  offset: null | number;
  limit: null | number;
}

export interface IStatsResponse {
  leads: number;
  totalIncomes: number;
  referrals: number;
  refIncomes: number;
}
