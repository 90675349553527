import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { tokenLSKey } from "@/constants";
import { IAddNote, INote } from "@/types";

export const notesAPI = createApi({
  reducerPath: "notesAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/notes",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    addNote: builder.mutation<INote, IAddNote>({
      query: (body) => ({
        url: "",
        method: "POST",
        body,
      }),
    }),
    removeNote: builder.mutation<{ message: string }, { id: number }>({
      query: (body) => ({
        url: "",
        method: "DELETE",
        body,
      }),
    }),
  }),
});

export const { useAddNoteMutation, useRemoveNoteMutation } = notesAPI;
