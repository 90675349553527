import { useGetWebmasterStatsQuery } from '@/redux/api/statsAPI';

export const WebmasterLeadsStatItems: React.FC<{ webmasterId: number }> = ({
  webmasterId,
}) => {
  const { data: stats } = useGetWebmasterStatsQuery({ id: webmasterId! });

  return (
    <div className="stats rounded-md shadow theme-border">
      <div className="stat">
        <div className="stat-title">Привел лидов</div>
        <div className="stat-value">{stats?.leads}</div>
      </div>

      <div className="stat">
        <div className="stat-title">Принес комиссионных</div>
        <div className="stat-value">
          {stats?.totalIncomes}
          <span className="font-medium text-xl pl-2">K</span>
        </div>
      </div>
    </div>
  );
};
