import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState, IWebmastersState, UsersSortType } from "@/types";

const initialState: IWebmastersState = {
  expandedRow: null,
  offset: 0,
  limit: 30,
  sort: UsersSortType.DATE_UP,
};

export const webmastersSlice = createSlice({
  initialState,
  name: "webmasters",
  reducers: {
    setExpandedRow: (state, action: PayloadAction<number | null>) => {
      state.expandedRow = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setSort: (state, action: PayloadAction<UsersSortType>) => {
      state.sort = action.payload;
    },
  },
});

export default webmastersSlice.reducer;

export const { setExpandedRow, setOffset, setSort } = webmastersSlice.actions;
export const webmasters = (state: RootState) => state.webmasters;
