import { ILead, LeadStatus } from '@/types';

import { LeadStatusBadge, TelephonyCompanyCeil } from '@/components';
import { routing } from '@/constants';
import { Link } from 'react-router-dom';
import { useAppSelector } from '@/hooks/useRedux';
import { webmasterLeads } from '@/redux/features/WebmasterLeadsSlice';

type Props = {
  leads: ILead[];
  type: 'all' | 'webmaster';
};

export const LeadsTable: React.FC<Props> = ({ leads, type }) => {
  const { telephony } = useAppSelector(webmasterLeads);

  return (
    <div className="overflow-x-auto">
      <table className="table w-full table-compact">
        <thead>
          <tr>
            <th>Дата</th>
            <th>ID</th>
            {type === 'all' && <th>Вебмастер</th>}

            <th>Категория</th>
            <th>Город</th>
            <th>Адрес</th>
            <th>Тел. клиента</th>

            {type === 'all' ? <th>Телефония</th> : <th>Рекл. компания</th>}
            <th>Сумма</th>
            <th>Комиссия</th>
            <th>Cтатус</th>
          </tr>
        </thead>
        <tbody>
          {leads.map((lead) => {
            return (
              <tr className="hover" key={lead.amoId}>
                <td>
                  {new Date(lead.createdAt)
                    .toLocaleDateString(navigator.language, {
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                    .replace(',', '')}
                </td>
                <td>{lead.amoId}</td>
                {type === 'all' && (
                  <td>
                    <div
                      className="tooltip"
                      data-tip={`ID: ${lead.webmasterId}`}
                    >
                      <Link
                        className="link-primary"
                        to={`/${routing.CABINET}/${routing.WEBMASTERS}/${lead.webmasterId}`}
                      >
                        {lead.webmasterLogin}
                      </Link>
                    </div>
                  </td>
                )}
                <td>{lead.categoryName ? lead.categoryName : '----'}</td>
                <td>{lead.cityName ? lead.cityName : '----'}</td>
                <td>{lead.address ? lead.address : '----'}</td>
                <td>{lead.leadPhone}</td>
                {type === 'all' ? (
                  <td>
                    {lead.trackingPhone === 'p_manually'
                      ? 'Вручную (Частное лицо)'
                      : lead.trackingPhone === 'c_manually'
                      ? 'Вручную (Компания)'
                      : lead.trackingPhone === 'c_api'
                      ? 'API (Компания)'
                      : lead.trackingPhone === 'p_api'
                      ? 'API (Частное лицо)'
                      : lead.trackingPhone}
                  </td>
                ) : (
                  <TelephonyCompanyCeil
                    number={lead.trackingPhone}
                    telephony={telephony}
                  />
                )}

                <td>
                  {lead.sum
                    ? lead.status === LeadStatus.APPROVED ||
                      lead.status === LeadStatus.IN_WORK
                      ? lead.sum + ' K'
                      : '----'
                    : '----'}
                </td>
                <td>
                  {lead.sum
                    ? lead.status === LeadStatus.APPROVED ||
                      lead.status === LeadStatus.IN_WORK
                      ? Math.round((lead.sum / 100) * 10) + ' K'
                      : '----'
                    : '----'}
                </td>
                <td>
                  <LeadStatusBadge
                    comment={lead.comment}
                    status={lead.status}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
