import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthLayout, Layout } from './components';
import { Login } from './pages/Login/Login';
import { routing } from './constants';

const BannedPage = lazy(() => import('./pages/BannedPage/BannedPage'));
const Offers = lazy(() => import('./pages/Offers/Offers'));
const SingleOffer = lazy(() => import('./pages/SingleOffer/SingleOffer'));
const News = lazy(() => import('./pages/News/News'));
const SingleNews = lazy(() => import('./pages/SingleNews/SingleNews'));
const Finances = lazy(() => import('./pages/Finances/Finances'));
const Profile = lazy(() => import('./pages/Profile/Profile'));
const Leads = lazy(() => import('./pages/Leads/Leads'));
const Webmasters = lazy(() => import('./pages/Webmasters/Webmasters'));
const Webmaster = lazy(() => import('./pages/Webmaster/Webmaster'));
const Registrations = lazy(() => import('./pages/Registrations/Registrations'));

const TermsOfServices = lazy(
  () => import('./pages/TermsOfServices/TermsOfServices')
);

export const Routing = () => {
  return (
    <Routes>
      <Route
        path={routing.TERMS_OF_SERVICES}
        element={
          <Suspense>
            <TermsOfServices />
          </Suspense>
        }
      />

      <Route path={routing.LOGIN} element={<Login />} />
      <Route
        path={routing.BANNED}
        element={
          <Suspense>
            <BannedPage />
          </Suspense>
        }
      />
      <Route path={routing.MAIN} element={<Navigate to={routing.CABINET} />} />

      <Route path={routing.CABINET} element={<AuthLayout />}>
        <Route path="" element={<Layout />}>
          <Route index element={<Navigate to={routing.LEADS} />} />
          <Route
            path={routing.LEADS}
            element={
              <Suspense>
                <Leads />
              </Suspense>
            }
          />
          <Route
            path={routing.WEBMASTERS}
            element={
              <Suspense>
                <Webmasters />
              </Suspense>
            }
          />
          <Route
            path={`${routing.WEBMASTERS}/:id`}
            element={
              <Suspense>
                <Webmaster />
              </Suspense>
            }
          />

          <Route
            path={routing.REGISTRATIONS}
            element={
              <Suspense>
                <Registrations />
              </Suspense>
            }
          />
          <Route
            path={routing.OFFERS}
            element={
              <Suspense>
                <Offers />
              </Suspense>
            }
          />
          <Route
            path={`${routing.OFFERS}/:id`}
            element={
              <Suspense>
                <SingleOffer />
              </Suspense>
            }
          />
          <Route
            path={routing.NEWS}
            element={
              <Suspense>
                <News />
              </Suspense>
            }
          />
          <Route
            path={`${routing.NEWS}/:id`}
            element={
              <Suspense>
                <SingleNews />
              </Suspense>
            }
          />
          <Route
            path={routing.PROFILE}
            element={
              <Suspense>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path={routing.FINANCES}
            element={
              <Suspense>
                <Finances />
              </Suspense>
            }
          />
          <Route path="*" element={<Navigate to={routing.LEADS} />} />
        </Route>
      </Route>

      <Route path="*" element={<Navigate to={routing.LOGIN} />} />
    </Routes>
  );
};
