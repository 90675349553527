import { forwardRef } from "react";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";

import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

registerLocale("ru", ru);
type ButtonProps = React.HTMLProps<HTMLButtonElement>;

type Props = {
  date: Date | null | undefined;
  dateHandler: (date: Date | null) => void;
};

export const DatePicker: React.FC<Props> = ({ date, dateHandler }) => {
  const CustomInput = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ value, onClick }, ref) => (
      <button
        className=" btn-sm bg-base-100 w-full border calendar-btn rounded-md md:w-[100px]"
        onClick={onClick}
        ref={ref}
      >
        {value ? value : "-- -- ----"}
      </button>
    )
  );

  return (
    <div className="picker">
      <ReactDatePicker
        dateFormat="dd/MM/yyyy"
        locale="ru"
        maxDate={new Date()}
        selected={date}
        onChange={dateHandler}
        customInput={<CustomInput />}
        calendarClassName="custom-calendar noselect"
      />
    </div>
  );
};
