import React, { useEffect, useState } from 'react';
import { NotificationManager as notify } from 'react-notifications';

import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { finances, togglePaymentModal } from '@/redux/features/financesSlice';
import { curator } from '@/redux/features/curatorSlice';
import { useGetPaymentMutation } from '@/redux/api/financesAPI';
import { IErrorResponse, LegalInformationStatus } from '@/types';

export const PaymentModal = () => {
  const dispatch = useAppDispatch();
  const { bankCards, balance, login, name, legalInformation } =
    useAppSelector(curator);
  const { paymentModalIsOpen } = useAppSelector(finances);

  const [selectedCardOrCheckingAccount, setSelectedCardOrCheckingAccount] =
    useState('none');
  const [sum, setSum] = useState<string>('');

  const [getPayment, { data, error }] = useGetPaymentMutation();

  const closeModalHandler = () => {
    setSelectedCardOrCheckingAccount('none');
    if (balance) {
      setSum(balance.balance.toString());
    } else {
      setSum('');
    }
    dispatch(togglePaymentModal());
  };
  const cardOrCheckingAccountChangeHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedCardOrCheckingAccount(e.target.value);
  };

  const sumChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSum(value);
  };

  const paymentHandler = () => {
    if (selectedCardOrCheckingAccount === 'none')
      return notify.error('Выберите карту/Рассчетный счет');
    if (!sum) return notify.error('Укажите сумму выплаты');
    if (parseInt(sum) < 500) return notify.error('Минимально - 500 К');
    if (balance && parseInt(sum) > balance?.balance)
      return notify.error('Укажите сумму не больше текущего баланса');
    dispatch(togglePaymentModal());
    const bankCardName = selectedCardOrCheckingAccount.split('|')[0];
    const bankCard = selectedCardOrCheckingAccount.split('|')[1];
    const fio = selectedCardOrCheckingAccount.split('|')[2];
    getPayment({
      sum: parseInt(sum),
      bankCard,
      bankCardName,
      fio,
      login: login!,
      name: name!,
    });
    setSelectedCardOrCheckingAccount('none');
  };

  useEffect(() => {
    if (balance?.balance) setSum(balance.balance.toString());
  }, [balance]);

  useEffect(() => {
    if (!data) return;
    notify.success('Заявка успешно создана!');
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    notify.error(data.message);
  }, [error]);

  return (
    <div className={`modal ${paymentModalIsOpen ? 'modal-open' : ''} `}>
      <div className="modal-box  relative">
        <h3 className="font-bold text-2xl mb-5">Заказать выплату</h3>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Выберите карту/Рассчетный счет</span>
          </label>
          <select
            className="select select-sm select-bordered w-full "
            value={selectedCardOrCheckingAccount}
            onChange={cardOrCheckingAccountChangeHandler}
          >
            <option disabled value="none">
              Выберите карту или рассчетный счет
            </option>

            {legalInformation?.status === LegalInformationStatus.CONFIRMED && (
              <option
                value={`Рассчетный счет|${legalInformation.checkingAccount}|${legalInformation.name}`}
              >
                Рассчетный счет | {legalInformation.checkingAccount}
              </option>
            )}

            {bankCards.map(({ number, bank, customBank, fio }) => {
              const val =
                (customBank ? customBank : bank) + '|' + number + '|' + fio;
              return (
                <option key={number} value={val}>
                  {customBank ? customBank : bank} | {number}
                </option>
              );
            })}
          </select>
        </div>

        <div className="form-control w-full ">
          <label className="label">
            <span className="label-text">Укажите сумму</span>
          </label>
          <input
            type="number"
            value={sum}
            onChange={sumChangeHandler}
            className="input input-bordered w-full input-sm"
          />
        </div>

        <div className="text-sm mt-4">
          Вывод средств осуществляется раз в месяц
        </div>

        <div className="modal-action">
          <button className="btn btn-sm btn-success " onClick={paymentHandler}>
            Заказать выплату
          </button>
          <button className="btn btn-sm btn-error" onClick={closeModalHandler}>
            отмена
          </button>
        </div>
      </div>
    </div>
  );
};
