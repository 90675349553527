import { Balance, MobileMenu } from "@/components";
import { useAppSelector } from "@/hooks/useRedux";
import { curator } from "@/redux/features/curatorSlice";

import "./SecondNavBar.css";

export const SecondNavBar = () => {
  const { balance } = useAppSelector(curator);

  return (
    <div className="navbar bg-base-100 lg:hidden">
      <MobileMenu />
      <div className="navbar-end w-4/6">
        <Balance balance={balance?.balance} />
      </div>
    </div>
  );
};
