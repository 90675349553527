import { ITelephony, IWebmasterLeadsState, RootState } from "@/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: IWebmasterLeadsState = {
  limit: 50,
  offset: 0,
  startDate: null,
  endDate: null,
  categoryId: undefined,
  cityId: undefined,
  lossReason: undefined,
  status: undefined,
  trackingPhone: undefined,
  telephony: [],
};

export const WebmasterLeadsSlice = createSlice({
  initialState,
  name: "webmasterLeads",
  reducers: {
    setLeadsStartDate: (state, action: PayloadAction<string | null>) => {
      if (action.payload) {
        state.startDate = action.payload;
      } else {
        state.startDate = null;
      }
    },
    setLeadsEndDate: (state, action: PayloadAction<string | null>) => {
      if (action.payload) {
        state.endDate = action.payload;
      } else {
        state.endDate = null;
      }
    },
    setCategoryId: (state, action: PayloadAction<number | undefined>) => {
      state.categoryId = action.payload;
    },
    setCityId: (state, action: PayloadAction<number | undefined>) => {
      state.cityId = action.payload;
    },
    setStatus: (state, action: PayloadAction<string | undefined>) => {
      state.status = action.payload;
    },
    setLossReason: (state, action: PayloadAction<string | undefined>) => {
      state.lossReason = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setTelephony: (state, action: PayloadAction<ITelephony[]>) => {
      state.telephony = action.payload;
    },
    setTrackingPhone: (state, action: PayloadAction<string | undefined>) => {
      state.trackingPhone = action.payload;
    },
  },
});

export default WebmasterLeadsSlice.reducer;

export const {
  setLeadsStartDate,
  setLeadsEndDate,
  setCategoryId,
  setCityId,
  setStatus,
  setLossReason,
  setOffset,
  setTelephony,
  setTrackingPhone,
} = WebmasterLeadsSlice.actions;
export const webmasterLeads = (state: RootState) => state.webmasterLeads;
