import { useState } from "react";
import { CopyIcon } from "@/components";
import copy from "copy-to-clipboard";

export const RefLink: React.FC<{ linkText: string }> = ({ linkText }) => {
  const [tooltip, setTooltip] = useState("Копировать");

  const clickHandler = () => {
    setTooltip("Скопировано");
    copy(linkText);
  };

  return (
    <span
      className="tooltip text-left"
      data-tip={tooltip}
      onMouseLeave={() => setTimeout(() => setTooltip("Копировать"), 500)}
    >
      <span className="link link-primary no-underline" onClick={clickHandler}>
        <span className="hidden md:inline-block">
          <CopyIcon />
        </span>
        {linkText}
      </span>
    </span>
  );
};
