import { useEffect, useState } from "react";
import { NotificationManager as notify } from "react-notifications";

import { useAddNoteMutation } from "@/redux/api/notesAPI";
import { IErrorResponse, NoteColors } from "@/types";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { curator } from "@/redux/features/curatorSlice";
import { webmastersAPI } from "@/redux/api/webmastersAPI";
import { webmasters } from "@/redux/features/webmastersSlice";

type Props = {
  webmasterId: number;
};

export const AddNote: React.FC<Props> = ({ webmasterId }) => {
  const dispatch = useAppDispatch();
  const { name, login } = useAppSelector(curator);
  const [addNote, { error, data, isLoading }] = useAddNoteMutation();
  const { offset, limit, sort } = useAppSelector(webmasters);

  const wrote = `${login}|${name}`;

  const [text, setText] = useState("");
  const [color, setColor] = useState<NoteColors | "none">("none");

  const changeColorHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    if (value === "none") return setColor("none");
    setColor(value as NoteColors);
  };

  const saveBtnHandler = () => {
    if (!text) return notify.error("Введите текст заметки");
    if (color === "none") return notify.error("Выберите приоритет заметки");

    addNote({
      webmasterId,
      text,
      color,
      wrote,
    });
  };

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    if (data) return notify.error(data.message);
    return notify.error("Что-то пошло не так");
  }, [error]);

  useEffect(() => {
    if (!data) return;
    setColor("none");
    setText("");
    try {
      dispatch(
        webmastersAPI.util.updateQueryData(
          "getWebmasters",
          { offset, limit, sort },
          (draft) => {
            draft.webmasters.forEach((w) => {
              if (w.id === webmasterId) w.notes.push(data);
            });
          }
        )
      );
      dispatch(
        webmastersAPI.util.updateQueryData(
          "getWebmaster",
          { webmasterId },
          (draft) => {
            draft.notes.push(data);
          }
        )
      );
    } catch (error) {
      console.log(error);
    }
  }, [data, dispatch, offset, limit, sort, webmasterId]);

  return (
    <>
      <div className="hidden md:flex">
        <div className="notes">
          <span className="min-w-[130px] text-sm">Новая заметка:</span>
          <select
            className="select select-bordered select-sm  select-priority"
            value={color}
            onChange={changeColorHandler}
          >
            <option value="none">Приоритет</option>
            <option value={NoteColors.BLUE}>Низкий (Синий)</option>
            <option value={NoteColors.YELLOW}>Средний (желтый)</option>
            <option value={NoteColors.RED}>Высокий (красный)</option>
          </select>
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Текст заметки"
            className="input input-bordered input-sm w-full note-text"
          />
          <button
            className={`btn notes-btn btn-primary min-w-[100px] ${
              isLoading ? "loading" : ""
            } btn-sm`}
            onClick={saveBtnHandler}
          >
            {isLoading || "Добавить"}
          </button>
        </div>
      </div>

      <div className="md:hidden w-full">
        <div className="notes flex-col w-full gap-3">
          <span
            className="text-sm"
            style={{ borderRadius: "0.5rem", minHeight: "32px" }}
          >
            Новая заметка:
          </span>
          <select
            className="select select-bordered select-sm  select-priority"
            style={{ maxWidth: "100%", borderRadius: "0.5rem" }}
            value={color}
            onChange={changeColorHandler}
          >
            <option value="none">Приоритет</option>
            <option value={NoteColors.BLUE}>Низкий (Синий)</option>
            <option value={NoteColors.YELLOW}>Средний (желтый)</option>
            <option value={NoteColors.RED}>Высокий (красный)</option>
          </select>
          <input
            style={{ borderRadius: "0.5rem" }}
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Текст заметки"
            className="input input-bordered input-sm w-full note-text"
          />
          <button
            className={`btn notes-btn btn-primary min-w-[100px] ${
              isLoading ? "loading" : ""
            } btn-sm`}
            onClick={saveBtnHandler}
            style={{ borderRadius: "0.5rem" }}
          >
            {isLoading || "Добавить"}
          </button>
        </div>
      </div>
    </>
  );
};
