import { UsersSortType } from "@/types";
import { useState } from "react";

import "./SortSelect.css";

type Props = {
  valueHandler: (type: UsersSortType) => void;
};

export const SortSelect: React.FC<Props> = ({ valueHandler }) => {
  const [selected, setSelected] = useState<UsersSortType>(
    UsersSortType.DATE_UP
  );

  const changeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const type = e.target.value as UsersSortType;
    valueHandler(type);
    setSelected(type);
  };
  return (
    <div className="bg-base-100 shadow theme-border rounded-md px-6 py-4 flex  gap-5 flex-grow ">
      <div className="w-full">
        <label className="label">
          <span className="label-text">Сортировать</span>
        </label>
        <select
          className="select select-sm sort-select select-bordered px-3 w-full lg:max-w-[180px] "
          value={selected}
          onChange={changeSelect}
        >
          <option value="date-up">по дате добавления ▼</option>
          <option value="date-down">по дате добавления ▲</option>
          <option value="sum-up"> по заработку ▼</option>
          <option value="sum-down"> по заработку ▲</option>
        </select>
      </div>
    </div>
  );
};
