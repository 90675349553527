import { useEffect, useState } from "react";
import { NotificationManager as notify } from "react-notifications";

import { useAppSelector } from "@/hooks/useRedux";
import { useGetRefVisitsQuery } from "@/redux/api/visitsAPI";
import { registrations } from "@/redux/features/registrationsSlice";
import { IErrorResponse } from "@/types";
import "./RegistrationsTable.css";
import { RegistrationsMobCollapse } from "../RegistrationsMobCollapse/RegistrationsMobCollapse";

const initTotalState = {
  visits: 0,
  unique: 0,
  registrations: 0,
  success: 0,
  reject: 0,
  waiting: 0,
};

export const RegistrationsTable = () => {
  const [loading, setLoading] = useState(false);
  const [start, setStart] = useState<string>();
  const [end, setEnd] = useState<string>();
  const [totals, setTotals] = useState(initTotalState);

  const { startDate, endDate } = useAppSelector(registrations);

  const { data, isLoading, isFetching, error } = useGetRefVisitsQuery({
    start,
    end,
  });

  const dataIsset = loading || !data?.length;

  useEffect(() => {
    if (startDate && endDate) {
      setStart(startDate);
      setEnd(endDate);
    } else {
      setStart(undefined);
      setEnd(undefined);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (!data || !data.length) return;
    const res = data.reduce(
      (acc, v) => {
        acc.visits += parseInt(v.visits);
        acc.unique += parseInt(v.unique);
        acc.registrations += parseInt(v.registrations);
        acc.success += parseInt(v.success);
        acc.reject += parseInt(v.reject);
        acc.waiting += parseInt(v.waiting);
        return acc;
      },
      { ...initTotalState }
    );

    setTotals(res);
  }, [data]);

  useEffect(() => {
    if (!error) return;
    const { data } = error as IErrorResponse;
    if (data) return notify.error(data.message);
    return notify.error("Что-то пошло не так");
  }, [error]);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (isFetching || isLoading) {
      setLoading(true);
    } else if (!isFetching && !isLoading) {
      timeout = setTimeout(() => setLoading(false), 200);
    }
    return () => clearTimeout(timeout);
  }, [isFetching, isLoading]);

  return (
    <>
      <div className={`theme-border regs-list ${dataIsset ? "empty" : ""}`}>
        {loading ? (
          <span className="btn btn-square loading"></span>
        ) : data?.length ? (
          <>
            <div className="w-full hidden md:block">
              <div className="overflow-x-auto">
                <table className="table w-full table-compact">
                  <thead>
                    <tr>
                      <th>Дата</th>
                      <th>Переходы</th>
                      <th>Уник. переходы</th>
                      <th>Регистрации</th>
                      <th>В ожидании</th>
                      <th>Отклоненные</th>
                      <th>Подтвержденные</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="hover">
                      <td className="font-medium">Всего:</td>
                      <td className="font-medium">{totals.visits}</td>
                      <td className="font-medium">{totals.unique}</td>
                      <td className="font-medium">{totals.registrations}</td>
                      <td className="font-medium">{totals.waiting}</td>
                      <td className="font-medium">{totals.reject}</td>
                      <td className="font-medium">{totals.success}</td>
                    </tr>

                    {data.map((visit) => (
                      <tr key={visit.date} className="hover">
                        <td>{visit.date}</td>
                        <td>{visit.visits}</td>
                        <td>{visit.unique}</td>
                        <td>{visit.registrations}</td>
                        <td>{visit.waiting}</td>
                        <td>{visit.reject}</td>
                        <td>{visit.success}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="block md:hidden rounded-md overflow-hidden shadow t-border">
              {data &&
                data.map((el, idx) => {
                  return <RegistrationsMobCollapse key={idx} data={el} />;
                })}
            </div>
          </>
        ) : (
          <span className="text-2xl no-data-text font-thin">
            Пока что данных нет
          </span>
        )}
      </div>
    </>
  );
};
