import { tokenLSKey } from "@/constants";
import {
  ICuratorWebmaster,
  IGetWebmastersQuery,
  IWebmaster,
  IWebmastersResponse,
} from "@/types";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { setTelephony } from "../features/WebmasterLeadsSlice";

export const webmastersAPI = createApi({
  reducerPath: "webmastersAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL + "/webmaster",
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);

      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["Webmasters"],
  endpoints: (builder) => ({
    getWebmasters: builder.query<IWebmastersResponse, IGetWebmastersQuery>({
      query: (params) => ({
        url: "all/",
        method: "GET",
        params,
      }),
      providesTags: (result) => ["Webmasters"],
    }),

    getWebmastersSearch: builder.query<
      { count: number; rows: IWebmaster[] },
      { query: string }
    >({
      query: (params) => ({
        url: "search",
        method: "GET",
        params,
      }),
    }),
    getWebmaster: builder.query<ICuratorWebmaster, { webmasterId: number }>({
      query: ({ webmasterId }) => ({
        url: `${webmasterId}`,
        method: "GET",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setTelephony(data.telephony));
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetWebmastersQuery,
  useGetWebmastersSearchQuery,
  useGetWebmasterQuery,
} = webmastersAPI;
