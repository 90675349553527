import { TrashIcon } from "@/components/";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { useRemoveNoteMutation } from "@/redux/api/notesAPI";
import { webmastersAPI } from "@/redux/api/webmastersAPI";
import { webmasters } from "@/redux/features/webmastersSlice";
import { INote, NoteColors } from "@/types";

import "./NotesList.css";

type Props = {
  notes: INote[];
  webmasterId: number;
};

export const NotesList: React.FC<Props> = ({ notes, webmasterId }) => {
  const dispatch = useAppDispatch();
  const { offset, limit, sort } = useAppSelector(webmasters);
  const [removeNote] = useRemoveNoteMutation();

  const removeHandler = (id: number) => {
    removeNote({ id });

    try {
      dispatch(
        webmastersAPI.util.updateQueryData(
          "getWebmasters",
          { offset, limit, sort },
          (draft) => {
            draft.webmasters.forEach((w) => {
              if (w.id === webmasterId) {
                w.notes = w.notes.filter((n) => n.id !== id);
              }
            });
          }
        )
      );
      dispatch(
        webmastersAPI.util.updateQueryData(
          "getWebmaster",
          { webmasterId },
          (draft) => {
            draft.notes = draft.notes.filter((n) => n.id !== id);
          }
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-2 w-full md:w-1/2">
      {notes.length ? (
        notes.map((note) => {
          const bgClass =
            note.color === NoteColors.BLUE
              ? "bg-info"
              : note.color === NoteColors.YELLOW
              ? "bg-warning"
              : note.color === NoteColors.RED
              ? "bg-error"
              : "";

          const { id, text } = note;

          return (
            <span key={id} className="note">
              <span
                className={`py-1 px-2 text-xs my-1 rounded-md ${bgClass}`}
                key={id}
              >
                {text}
              </span>
              <div className="remove" onClick={() => removeHandler(id)}>
                <TrashIcon />
              </div>
            </span>
          );
        })
      ) : (
        <span className="text-xl no-data-text mb-3 block font-thin text-center md:text-left">
          Пока заметок нет
        </span>
      )}
    </div>
  );
};
