import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { tokenLSKey } from "@/constants";
import { setCurator } from "../features/curatorSlice";
import { IBankCard, ICurator } from "@/types";

export const curatorAPI = createApi({
  reducerPath: "curatorAPI",

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenLSKey);
      if (token) headers.set("authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (credentials) => ({
        url: "/auth/curator/login",
        method: "POST",
        body: credentials,
      }),
    }),

    getMe: builder.query<any, null>({
      query: () => ({
        url: "/curator/",
        method: "GET",
      }),

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCurator(data));
        } catch (error) {}
      },
    }),

    setBankCards: builder.mutation<ICurator, { bankCards: IBankCard[] }>({
      query: (body) => ({
        url: "/curator/card",
        method: "PATCH",
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCurator(data));
        } catch (error) {}
      },
    }),
    changePassword: builder.mutation<
      ICurator,
      { password: string; newPassword: string }
    >({
      query: (body) => ({
        url: "/curator/password",
        method: "PATCH",
        body,
      }),
    }),

    changeContacts: builder.mutation<
      ICurator,
      { workTelegram?: string; workPhone?: string; name: string }
    >({
      query: (body) => ({
        url: "/curator/contacts",
        method: "PATCH",
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCurator(data));
        } catch (error) {}
      },
    }),
  }),
});

export const {
  useGetMeQuery,
  useLoginMutation,
  useChangePasswordMutation,
  useChangeContactsMutation,
  useSetBankCardsMutation,
} = curatorAPI;
