import { useEffect, useState } from 'react';
import {
  NotesIcon,
  TelephonyCeil,
  AddNote,
  NotesList,
  LegalInformationStatusBadge,
} from '@/components';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { setExpandedRow, webmasters } from '@/redux/features/webmastersSlice';
import { IWebmaster } from '@/types';

import './WebmasterTableRow.css';
import { Link } from 'react-router-dom';
import { routing } from '@/constants';

type Props = {
  webmaster: IWebmaster;
};

export const WebmasterTableRow: React.FC<Props> = ({ webmaster }) => {
  const dispatch = useAppDispatch();
  const { expandedRow } = useAppSelector(webmasters);
  const [bgNote, setBgNote] = useState('');
  const {
    id,
    login,
    createdAt,
    telephony,
    workTelegram,
    workPhone,
    balance,
    isBanned,
    notes,
    legalInformation,
  } = webmaster;

  const isOpen = expandedRow && expandedRow === id;

  const toggleExpand = (id: number) => {
    if (expandedRow === id) return dispatch(setExpandedRow(null));
    dispatch(setExpandedRow(id));
  };

  useEffect(() => {
    const colors = {
      red: 0,
      yellow: 0,
      blue: 0,
    };
    notes.forEach((n) => (colors[n.color] = colors[n.color] + 1));
    if (colors.red) return setBgNote('bg-error');
    if (colors.yellow) return setBgNote('bg-warning');
    if (colors.blue) return setBgNote('bg-info');
  }, [notes]);

  return (
    <>
      <tr className="hover">
        <td>{id}</td>
        <td className="font-medium">
          <Link
            className="link-primary"
            to={`/${routing.CABINET}/${routing.WEBMASTERS}/${id}`}
          >
            {login}
          </Link>
        </td>
        <td>
          <span className="expand-icon" onClick={() => toggleExpand(id)}>
            {notes && notes.length > 0 && (
              <div className={`counter ${bgNote}`}></div>
            )}
            <NotesIcon />
          </span>
        </td>
        <td>{new Date(createdAt).toLocaleDateString()}</td>
        <td>
          {telephony.length
            ? telephony.map((t: any) => (
                <TelephonyCeil
                  key={t.number}
                  name={t.name}
                  number={t.number}
                  type={t.type}
                />
              ))
            : '---'}
        </td>
        <td className="font-medium">{balance.balance} K</td>
        <td>{balance.hold} K</td>
        <td>{balance.totalEarned} K</td>
        <td>{Math.round((balance.totalEarned / 100) * 10)} K</td>
        <td>
          {workTelegram ? (
            <a
              className="link link-primary"
              target="_blank"
              href={`https://t.me/${workTelegram}`}
              rel="noreferrer"
            >
              @{workTelegram}
            </a>
          ) : (
            '---'
          )}
        </td>
        <td>{workPhone || '---'}</td>
        <td>
          {isBanned ? (
            <span className="badge badge-error">Забанен</span>
          ) : (
            <span className="badge badge-success">Активен</span>
          )}
        </td>
        <td>
          <LegalInformationStatusBadge
            status={legalInformation?.status}
            size="md"
          />
        </td>
      </tr>
      {isOpen && (
        <tr className="expand">
          <td colSpan={12}>
            <div className="py-4 px-2">
              <div className="form-control w-1/2 row-notes">
                <AddNote webmasterId={id} />
              </div>
            </div>

            <NotesList notes={notes} webmasterId={id} />
          </td>
        </tr>
      )}
    </>
  );
};
