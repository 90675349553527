import { IVisit } from "@/types";
import { FC, useState } from "react";

type Props = {
  data: IVisit;
};

export const RegistrationsMobCollapse: FC<Props> = ({ data }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div
      className={`mob-collapse bg-base-100 theme-border last:border-b-0 collapse collapse-arrow ${
        isOpen ? "collapse-open" : ""
      }`}
    >
      <div
        className="collapse-title cursor-pointer min-h-[auto] py-2 px-2 min-[360px]:px-5"
        onClick={() => setIsOpen((p) => !p)}
      >
        <div className="flex gap-2 items-center flex-wrap justify-between">
          <div className="mr-auto text-sm">{data.date}</div>
        </div>
      </div>

      <div className="collapse-content px-2 min-[360px]:px-5">
        <div>
          <hr className={`divider h-[1px] ${isOpen && "mt-0"}`} />
          <div className="flex flex-col items-start gap-1">
            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Дата:</span>
              </label>
              <div>{data.date}</div>
            </div>
            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Переходы:</span>
              </label>
              <div>{data.visits}</div>
            </div>
            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Уник. переходы:</span>
              </label>
              <div>{data.unique}</div>
            </div>
            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Регистрации:</span>
              </label>
              <div>{data.registrations}</div>
            </div>
            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">В ожидании:</span>
              </label>
              <div>{data.waiting}</div>
            </div>
            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Отклоненные:</span>
              </label>
              <div>{data.reject}</div>
            </div>
            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Подтвержденные:</span>
              </label>
              <div>{data.success}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
