import { IWebmaster } from '@/types';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { routing } from '@/constants';
import { TelephonyCeil } from '../TelephonyCeil/TelephonyCeil';
import { AddNote } from '../AddNote/AddNote';
import { NotesList } from '../NotesList/NotesList';

type Props = {
  data: IWebmaster;
};

export const WebmastersMobCollapse: FC<Props> = ({ data }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenNotes, setIsOpenNotes] = useState<boolean>(false);

  return (
    <div
      className={`mob-collapse bg-base-100 theme-border last:border-b-0 collapse ${
        isOpen ? 'collapse-open' : ''
      }`}
    >
      <div
        className="collapse-title cursor-pointer min-h-[auto] py-2 px-2 min-[360px]:px-5"
        onClick={() => setIsOpen((p) => !p)}
      >
        <div className="flex gap-2 items-center flex-wrap justify-between">
          <div className="mr-auto text-sm">{data.login}</div>
          <div>
            {data.isBanned ? (
              <span className="badge badge-sm badge-error">Забанен</span>
            ) : (
              <span className="badge badge-sm badge-success">Активен</span>
            )}
          </div>
        </div>
      </div>

      <div className="collapse-content px-2 min-[360px]:px-5">
        <div>
          <hr className={`divider h-[1px] ${isOpen && 'mt-0'}`} />
          <div className="flex flex-col items-start gap-1">
            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">ID:</span>
              </label>
              <div>
                <Link
                  className="link-primary"
                  to={`/${routing.CABINET}/${routing.WEBMASTERS}/${data.id}`}
                >
                  {data.login}
                </Link>
              </div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Логин:</span>
              </label>
              <div>{data.login}</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Добавлен:</span>
              </label>
              <div>{new Date(data.createdAt).toLocaleDateString()}</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Рекл. кампании:</span>
              </label>
              <div className={`${data.telephony.length ? 'w-full' : ''}`}>
                {data.telephony.length
                  ? data.telephony.map((t) => (
                      <TelephonyCeil
                        key={t.number}
                        name={t.name}
                        number={t.number}
                        type={t.type}
                      />
                    ))
                  : '---'}
              </div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Баланс:</span>
              </label>
              <div>{data.balance.balance} K</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Холд:</span>
              </label>
              <div>{data.balance.hold} K</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Заработал:</span>
              </label>
              <div>{data.balance.totalEarned} K</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Комиссионные:</span>
              </label>
              <div>{Math.round((data.balance.totalEarned / 100) * 10)} K</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Телеграм:</span>
              </label>
              <div>
                {data.workTelegram ? (
                  <a
                    className="link link-primary"
                    target="_blank"
                    href={`https://t.me/${data.workTelegram}`}
                    rel="noreferrer"
                  >
                    @{data.workTelegram}
                  </a>
                ) : (
                  '---'
                )}
              </div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Телефон:</span>
              </label>
              <div>{data.workPhone || '---'}</div>
            </div>

            <div className="flex items-center gap-2 flex-wrap">
              <label className="label p-0">
                <span className="label-text font-medium">Статус:</span>
              </label>
              <div>
                {data.isBanned ? (
                  <span className="badge badge-error">Забанен</span>
                ) : (
                  <span className="badge badge-success">Активен</span>
                )}
              </div>
            </div>
          </div>
          <button
            className="mt-5 text-primary"
            onClick={() => setIsOpenNotes((prev) => !prev)}
          >
            {isOpenNotes ? 'Свернуть' : 'Заметки'}
          </button>
          {isOpenNotes && (
            <div>
              <div className="py-4">
                <div className="form-control w-full row-notes">
                  <AddNote webmasterId={data.id} />
                </div>
              </div>

              <div>
                <NotesList notes={data.notes} webmasterId={data.id} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
