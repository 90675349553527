import { useGetWebmasterStatsQuery } from '@/redux/api/statsAPI';

export const WebmasterRefStatItems: React.FC<{ webmasterId: number }> = ({
  webmasterId,
}) => {
  const { data: stats } = useGetWebmasterStatsQuery({ id: webmasterId! });

  return (
    <div className="stats rounded-md shadow theme-border">
      <div className="stat">
        <div className="stat-title">Доход от рефералов</div>
        <div className="stat-value">
          {stats?.refIncomes}
          <span className="font-medium text-xl pl-2">K</span>
        </div>
      </div>
      <div className="stat">
        <div className="stat-title">Рефералов</div>
        <div className="stat-value">{stats?.referrals}</div>
      </div>
    </div>
  );
};
